import { FC, ReactNode, memo, useMemo } from 'react'

import { CarouselProps, Carousel as Wrapped } from 'antd'

import { useDragDetection } from '../../hooks/useDragDetection'
import { withPrefix } from '../../providers/ConfigProvider'
import Breakpoint, { useResponsive } from '../screen'
import './styles/CardCarousel.scss'

export type CardCarouselProps = {
  navigation?: boolean
  dots?: boolean
  onChange?: (from: number, to: number) => void
  size?: number
  overflow?: boolean | 'mobile'
} & CarouselProps

const CardCarousel: FC<CardCarouselProps> = ({
  navigation = false,
  dots = true,
  onChange,
  children,
  arrows,
  className = '',
  size = 1,
  overflow = true,
  centerMode = false,
  centerPadding = '32px',
  responsive = [
    {
      breakpoint: Breakpoint.MD,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: Breakpoint.SM,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  ...props
}) => {
  const { isMobile } = useResponsive()
  const overflowClass =
    typeof overflow === 'boolean' && overflow
      ? 'card-carousel-overflow'
      : overflow === 'mobile'
        ? 'card-carousel-overflow-mobile'
        : ''
  const { handleMouseDown, handleChildClick } = useDragDetection()

  const ComponentWrapper = memo(({ children }: { children: ReactNode }) => (
    <div className={withPrefix('card-carousel-item')} onMouseDown={handleMouseDown} onClick={handleChildClick}>
      {children}
    </div>
  ))

  const cards = useMemo(() => {
    return Array.isArray(children)
      ? children?.map((child, index) => <ComponentWrapper key={index}>{child}</ComponentWrapper>)
      : [<ComponentWrapper key={0}>{children}</ComponentWrapper>]
  }, [children])

  return (
    <div
      className={withPrefix(
        'card-carousel',
        cards.length <= 1 ? 'card-carousel-single' : '',
        cards.length > 1 ? overflowClass : '',
        className,
      )}
    >
      <Wrapped
        centerMode={centerMode ?? isMobile}
        centerPadding={centerPadding}
        arrows={arrows}
        dots={dots && cards.length > 1}
        draggable={cards.length > 1}
        dotPosition={'top'}
        speed={500}
        beforeChange={onChange}
        slidesToShow={size}
        slidesToScroll={size}
        responsive={size > 1 ? responsive : undefined}
        {...props}
      >
        {cards}
      </Wrapped>
    </div>
  )
}

export { CardCarousel }
