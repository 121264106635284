import { Entity, validateRequired } from '@data-client/rest'

import dayjs from 'dayjs'

import { capitalize } from 'src/helpers/strings'
import { AccountEntity } from './account/account'
import { BaseAddress } from './address'
import { CreatePaymentEndpoint } from './api/PaymentEndpoint'
import { ApiEndpoint, createApiResource } from './api/endpoint'
import { ApiEntity } from './api/entity'
import { MembershipCardEntity } from './membership'
import { OrderEntity } from './orders/order'
import { SubscriptionEntity } from './subscription'
import { Wallet } from './wallet/wallet'

type TransactionType =
  | ''
  | 'application'
  | 'appointment'
  | 'charge'
  | 'credit'
  | 'donation'
  | 'event'
  | 'event_ticket'
  | 'fundraiser'
  | 'giftcard'
  | 'invoice'
  | 'invoices'
  | 'invoicing'
  | 'membership'
  | 'order'
  | 'schedule'
  | 'schedule_book'
  | 'schedule_slot'
  | 'subscription'

export type TransactionStatus =
  | ''
  | 'cancelled'
  | 'declined'
  | 'voided'
  | 'payment'
  | 'error'
  | 'authorized'
  | 'refunded'
  | 'processed'
  | 'processing'
  | 'invoiced'
  | 'charged_back'


export type TransactionPaymentType = '' | 'creditcard' | 'ach' | 'giftcard' | 'invoice' | 'pms' | 'entry' | 'cash' | 'payment' | 'voucher' | 'wire' | 'papercheck' | 'pos' | 'spa'

export const PaymentTypeLong: { [key in TransactionPaymentType]: string } = {
  'creditcard': 'Credit Card',
  'ach': 'Bank Transfer',
  'giftcard': 'Gift Card',
  'invoice': 'House Account',
  'pms': 'Hotel Payment',
  'entry': 'Entry',
  'cash': 'Cash',
  'payment': 'House Account',
  'voucher': 'Voucher',
  'wire': 'Wire Transfer',
  'papercheck': 'Check',
  'pos': 'F&B Payment',
  'spa': 'Spa Payment',
  '': '',
}

export type TransactionData = {
  quantity: number
  fee: number
  amount: number
  referenceId?: string
  referenceNo?: number
  subscriptionId?: number
  referenceItem?: string
  referenceType?: string
}

export type TransactionQuery = {
  status?: TransactionStatus
  type?: TransactionType
} & API.PaginationParams

class TransactionEntity extends ApiEntity implements Data.Transactional {
  static urlRoot = `/api/account/transactions`

  readonly orderId: Data.ID = 0
  readonly brand: Design.PaymentBrandIcon
  readonly lastFour?: string
  readonly billingAddress?: BaseAddress
  readonly paymentType: TransactionPaymentType = ''
  readonly status: TransactionStatus = ''
  readonly purchaseOrder: string = ''
  readonly linkedTransactionId: number = 0
  readonly referenceId: number = 0
  readonly type: TransactionType = ''
  readonly refundedAmount: number = 0
  readonly amount: number = 0
  readonly amountDue: number = 0
  readonly subTotal: number = 0
  readonly tax: number = 0
  readonly discount: number = 0
  readonly createdOn: Date = new Date()
  readonly paidOn: Date = new Date()
  readonly chargedOn: Date = new Date()
  readonly periodStart: Date = new Date()
  readonly periodEnd: Date = new Date()
  readonly currency: string = ''
  readonly data: TransactionData[] = []
  readonly description?: string
  readonly subscription?: SubscriptionEntity
  readonly membershipCard?: MembershipCardEntity
  readonly customer: AccountEntity = AccountEntity.fromJS()
  readonly wallet?: Wallet
  readonly linked: TransactionEntity[] = []
  readonly order?: OrderEntity

  get paymentDescription() {
    if (this.brand) {
      let description = [capitalize(this.brand)]
      if (this.lastFour) {
        description.push(this.lastFour.replaceAll('*', ''))
      }
      return description.join(' •• ')
    } else {
      return PaymentTypeLong[this.paymentType]
    }
  }
}

class ChargeError {
  message: string = ''
  referenceId: string
  title: string
}

class ChargeResponse extends Entity implements API.PaymentResponse {
  static schema = {
    transaction: TransactionEntity,
  }
  amount: number = 0
  amountCharged: number = 0
  success: boolean = false
  error?: ChargeError
  transaction: TransactionEntity = TransactionEntity.fromJS()

  pk() {
    return `${this.transaction.id}`
  }
}

export type StatementQuery = {
  startDate?: string
  endDate?: string
  type?: 'membership' | 'subscription'
} & API.PaginationParams


/** The StatementSummary class is used to represent a summary of a statement.*/
class StatementSummary extends ApiEntity implements Data.Temporal {
  static schema = {
    startDate: (iso: string) => dayjs(iso).toDate(),
    endDate: (iso: string) => dayjs(iso).toDate(),
  }
  static urlRoot = `/api/account/transactions/statements`
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly count: number = 0
  readonly charged: number = 0
  readonly total: number = 0
  readonly refunded: number = 0
  readonly periodDue: number = 0
  readonly totalDue: number = 0

  static key = "StatementEntity"

  startMonth(): string {
    return dayjs(this.startDate).format('MMMM')
  }

  startYear(): string {
    return dayjs(this.startDate).format('YYYY')
  }
}


/** The StatementEntity class is used to represent a statement which also includes all of the transactions.*/
class StatementEntity extends StatementSummary {
  readonly transactions: TransactionEntity[] = []

  static validate(processedEntity: any) {
    return validateRequired(processedEntity, this.defaults);
  }

  static schema = {
    startDate: (iso: string) => dayjs(iso).toDate(),
    endDate: (iso: string) => dayjs(iso).toDate(),
    transactions: [TransactionEntity],
  }
}

class TransactionRefundItem extends ApiEntity {
  readonly amount: number = 0
  readonly lastFour: string = ''
  readonly brand: Design.PaymentBrandIcon
  readonly paymentType: TransactionPaymentType = ''
  readonly status: TransactionStatus = ''
  readonly createdOn: Date = new Date()
}

const TransactionBase = createApiResource({
  path: '/api/account/transactions/:id',
  schema: TransactionEntity,
  searchParams: {} as TransactionQuery,
})
const send = new ApiEndpoint({
  path: '/api/account/transactions/:id/email',
  method: 'POST',
  body: {} as {
    callback: Data.NotificationCallback
  }
})

const statements = createApiResource({
  path: '/api/account/transactions/statements/:id',
  schema: StatementEntity,
  listSchema: StatementSummary,
  searchParams: {} as StatementQuery & API.PaginationParams,
})

const payInvoice = CreatePaymentEndpoint({
  path: '/api/account/transactions/:id/pay',
  schema: ChargeResponse,
})

const TransactionResource = {
  ...TransactionBase,
  send,
  payInvoice,
  statements,
}

export {
  ChargeResponse, StatementEntity, StatementSummary, TransactionEntity,
  TransactionRefundItem,
  TransactionResource
}

