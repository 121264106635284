import { FC, PropsWithChildren, useMemo } from 'react'

import { FormInstance } from 'antd'

import type { StripeCardNumberElement } from '@stripe/stripe-js'

import { FormRuleProps } from '../../components/form/Form'
import { PaymentFormValue } from '../../datasource/checkout'
import { PaymentMethod } from '../../datasource/payment'
import { CreditCardWallet } from '../../datasource/wallet/wallet'
import { useConfig } from '../../providers'
import StripeProvider from './stripe'

export type CardInformationProps = {
  rules?: FormRuleProps<Partial<PaymentFormValue>>
  disabled?: boolean
  form?: FormInstance
  gutter?: number | [number, number]
  onValidate?: (valid: boolean) => void
}

export type NewPaymentMethod = {
  paymentMethod: PaymentMethod
  cardDetails?: CreditCardWallet
}

export interface PaymentInterface {
  createPaymentMethod: ({
    paymentMethod,
    cardDetails,
  }: {
    paymentMethod: PaymentMethod
    cardDetails: StripeCardNumberElement
  }) => Promise<{ paymentMethod: PaymentMethod; cardDetails: CreditCardWallet }>
}

const AddPaymentMethodForm: FC = () => <></>

const PaymentProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    defaultProcessor,
    stripeProcessor,
    intl: { currency },
  } = useConfig()
  return useMemo(() => {
    if (
      (defaultProcessor && stripeProcessor?.id && defaultProcessor?.name === 'stripe') ||
      (defaultProcessor?.name === 'stripev2' && stripeProcessor?.id && stripeProcessor.key)
    ) {
      return (
        <StripeProvider
          id={stripeProcessor.id}
          token={stripeProcessor.key}
          currency={currency}
          acct={stripeProcessor.acct}
        >
          {children}
        </StripeProvider>
      )
    } else {
      return <>{children}</>
    }
  }, [defaultProcessor, stripeProcessor])
}

// const PaymentProvider: SDK.Components.PaymentProcessorContext = ({ children }) => {
//   return <PaymentProviderByName>{children}</PaymentProviderByName>
// }

// PaymentProvider.AddPaymentMethodForm = AddPaymentMethodForm

export { PaymentProvider }
