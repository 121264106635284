import { lazy } from 'react'

import { Col, Input, Row, Tabs } from 'antd'

import { useLoading } from '@data-client/hooks'
import { AsyncBoundary } from '@data-client/react'

import Form, { Button, Item } from '../form'
import { OverlayLoader, Spinner } from '../loader'
import { Title } from '../text/Title'
import { useDeveloperAuth } from './DebugAuth'

const DebugCompany = lazy(() => import('./tabs/DebugCompany'))
const PortalSelect = lazy(() => import('./tabs/DebugPortalSelect'))
// const DebugApp = lazy(() => import('./tabs/DebugApp'))
// const DebugConfig = lazy(() => import('./tabs/DebugConfig'))
// const DebugLoginConfig = lazy(() => import('./tabs/DebugLoginConfig'))
// const DebugProps = lazy(() => import('./tabs/DebugProps'))

const tabs = [
  {
    label: 'Companies',
    key: 'company',
    Component: DebugCompany,
  },
  {
    label: 'Portals',
    key: 'portals',
    Component: PortalSelect,
  },
  // {
  //   label: 'Company Settings',
  //   key: 'config',
  //   Component: DebugConfig,
  // },
  // {
  //   label: 'Theme',
  //   key: 'theme',
  //   Component: DebugProps,
  // },
  // {
  //   label: 'Login',
  //   key: 'login',
  //   Component: DebugLoginConfig,
  // },
  // {
  //   label: 'App',
  //   key: 'app',
  //   Component: DebugApp,
  // },
]

const DeveloperLogin = () => {
  const { authenticate } = useDeveloperAuth()
  const [onFinish, loading] = useLoading(
    (credentials: API.LoginRequest<'password'>) => {
      return authenticate(credentials.username, credentials.password)
    },
    [authenticate],
  )

  return (
    <OverlayLoader loading={loading}>
      <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
        <Col>
          <Title level={3} style={{ paddingBottom: 30 }}>
            Admin Login
          </Title>
          <Form onFinish={onFinish}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Item name={'username'} label={'Username or Email'}>
                  <Input required />
                </Item>
              </Col>
              <Col span={24}>
                <Item name={'password'} label={'Password'}>
                  <Input.Password required />
                </Item>
              </Col>
              <Col span={24}>
                <Button loading={loading} block htmlType={'submit'}>
                  Login
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </OverlayLoader>
  )
}

const DeveloperWindow = () => {
  const { authenticated } = useDeveloperAuth()

  return !authenticated ? (
    <DeveloperLogin />
  ) : (
    <Tabs
      items={tabs.map(({ Component, key, ...props }) => ({
        children: (
          <AsyncBoundary fallback={<Spinner fullscreen />}>
            <Component />
          </AsyncBoundary>
        ),
        key,
        ...props,
      }))}
    />
  )
}

export default DeveloperWindow
