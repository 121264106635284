import type { PolymorphicInterface } from '@data-client/endpoint/lib/interface'
import { Entity, schema } from '@data-client/rest'

abstract class ApiEntity extends Entity implements Data.Identified<Data.ID> {
  id: Data.ID = ''

  pk(): string {
    return `${this.id}`
  }
}

class ApiObject extends Entity {
  pk(): undefined {
    return undefined
  }
}

class Singleton extends ApiEntity {
  pk(parent?: any, key?: string, args?: readonly any[]): string {
    return 'singleton'
  }
}

class ApiCollection<
  S extends any[] | PolymorphicInterface = any,
  Args extends any[] =
  | []
  | [urlParams: Record<string, any>]
  | [urlParams: Record<string, any>, body: any],
  Parent = any
> extends schema.Collection<S, Args, Parent> {
  static nonFilterArgumentKeys(key: keyof API.Pagination) {
    return key === 'orderBy' || key === 'pageNumber' || key === 'pageSize'
  }
}


export default ApiEntity

export { ApiCollection, ApiEntity, ApiObject, Singleton }

