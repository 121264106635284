export type ColorKeys =
  | '@colors-gray-1'
  | '@colors-gray-2'
  | '@colors-gray-3'
  | '@colors-gray-4'
  | '@colors-gray-5'
  | '@colors-gray-6'
  | '@colors-gray-7'
  | '@colors-gray-8'
  | '@colors-gray-9'
  | '@colors-gray-10'
  | '@colors-green-0'
  | '@colors-green-1'
  | '@colors-green-2'
  | '@colors-green-3'
  | '@colors-green-4'
  | '@colors-green-5'
  | '@colors-green-6'
  | '@colors-green-7'
  | '@colors-green-8'
  | '@colors-green-9'
  | '@colors-green-10'
  | '@screen-lg-max'
  | '@screen-lg-min'
  | '@screen-lg'
  | '@screen-md-max'
  | '@screen-md-min'
  | '@screen-md'
  | '@screen-sm-max'
  | '@screen-sm-min'
  | '@screen-sm'
  | '@screen-xl-max'
  | '@screen-xl-min'
  | '@screen-xl'
  | '@screen-xs-max'
  | '@screen-xs-min'
  | '@screen-xs'
  | '@screen-xxl-max'
  | '@screen-xxl-min'
  | '@screen-xxl'
  | '@screen-xxxl-min'
  | '@screen-xxxl'
  | '@animation-duration-base'
  | '@animation-duration-slow'
  | '@animation-duration-fast'
  | '@primary-color'
  | '@success-color'
  | '@info-color'
  | '@error-color'
  | '@warning-color'
  | '@danger-color'
  | '@accent-color'
  | '@white'
  | '@disabled-color'
  | '@font-family-primary'
  | '@font-family-secondary'
  | '@font-family-tertiary'
  | '@border-width-base'
  | '@border-style-base'
  | '@border-radius-base'
  | '@border-radius-sm'
  | '@border-color-inverse'
  | '@border-color-base'
  | '@border-color-focused'
  | '@border-radius-base'
  | '@border-radius-lg'
  | '@border-radius-md'
  | '@border-radius-sm'
  | '@border-radius-xs'
  | '@alert-error-bg-color'
  | '@alert-message-color'
  | '@alert-error-border-color'
  | '@alert-error-icon-color'
  | '@alert-error-message-color'
  | '@alert-info-bg-color'
  | '@alert-info-border-color'
  | '@alert-info-icon-color'
  | '@alert-info-message-color'
  | '@alert-success-bg-color'
  | '@alert-success-border-color'
  | '@alert-success-icon-color'
  | '@alert-success-message-color'
  | '@alert-warning-bg-color'
  | '@alert-warning-border-color'
  | '@alert-warning-icon-color'
  | '@alert-warning-message-color'
  | '@alert-with-description-icon-size'
  | '@toast-success-bg'
  | '@toast-success-title-color'
  | '@toast-success-message-color'
  | '@toast-success-icon-color'
  | '@toast-error-bg'
  | '@toast-error-title-color'
  | '@toast-error-message-color'
  | '@toast-error-icon-color'
  | '@toast-warning-bg'
  | '@toast-warning-title-color'
  | '@toast-warning-message-color'
  | '@toast-warning-icon-color'
  | '@toast-info-bg'
  | '@toast-info-title-color'
  | '@toast-info-message-color'
  | '@toast-info-icon-color'
  | '@drawer-body-padding'
  | '@drawer-bg'
  | '@drawer-header-close-size'
  | '@drawer-header-close-bg'
  | '@drawer-header-close-color'
  | '@drawer-header-close-hover-color'
  | '@drawer-header-close-hover-bg'
  | '@avatar-bg'
  | '@avatar-color'
  | '@icon-color'
  | '@badge-count-bg'
  | '@badge-count-color'
  | '@badge-color'
  | '@badge-font-family'
  | '@badge-font-size'
  | '@tag-default-bg'
  | '@tag-default-color'
  | '@tag-font-size'
  | '@tag-font-family'
  | '@tabs-font-family'
  | '@tabs-font-size'
  | '@tabs-active-color'
  | '@background-color-base'
  | '@body-background'
  | '@layout-body-background'
  | '@layout-footer-background'
  | '@layout-footer-height'
  | '@layout-footer-padding'
  | '@layout-header-background'
  | '@layout-header-border-color'
  | '@layout-header-color'
  | '@layout-header-height'
  | '@layout-header-mobile-height'
  | '@layout-header-padding'
  | '@layout-section-padding-horizontal'
  | '@layout-section-padding-vertical'
  | '@layout-section-padding'
  | '@layout-section-title-font-family'
  | '@layout-sider-background'
  | '@layout-sider-width'
  | '@layout-sider-collapsed-width'
  | '@cart-icon-bg'
  | '@cart-icon-color'
  | '@breadcrumb-base-color'
  | '@breadcrumb-font-size'
  | '@breadcrumb-font-family'
  | '@breadcrumb-last-item-color'
  | '@breadcrumb-link-color-hover'
  | '@breadcrumb-link-color'
  | '@breadcrumb-separator-color'
  | '@btn-font-family'
  | '@btn-font-weight'
  | '@btn-text-transform'
  | '@btn-default-bg'
  | '@btn-default-color'
  | '@btn-default-border'
  | '@btn-default-disabled-color'
  | '@btn-default-hover-color'
  | '@btn-default-hover-bg'
  | '@btn-default-hover-border'
  | '@btn-ghost-bg'
  | '@btn-ghost-border'
  | '@btn-ghost-color'
  | '@btn-ghost-hover-bg'
  | '@btn-ghost-hover-color'
  | '@btn-ghost-hover-border'
  | '@btn-disable-bg'
  | '@btn-disable-color'
  | '@btn-disable-border'
  | '@btn-border-radius'
  | '@btn-font-size-lg'
  | '@btn-font-weight'
  | '@btn-font-size'
  | '@btn-height-base'
  | '@btn-height-lg'
  | '@btn-primary-bg'
  | '@btn-primary-color'
  | '@btn-primary-border-color'
  | '@btn-primary-hover-color'
  | '@btn-primary-hover-bg'
  | '@btn-primary-hover-border-color'
  | '@btn-square-only-icon-size'
  | '@card-bg'
  | '@card-selected-bg'
  | '@card-hoverable-hover-border'
  | '@card-hoverable-hover-shadow'
  | '@card-badge-bg'
  | '@card-badge-color'
  | '@card-shadow'
  | '@card-subtitle-font-family'
  | '@card-meta-font-family'
  | '@card-blur-bg-transparency'
  | '@component-background'
  | '@component-color'
  | '@collapse-header-bg'
  | '@collapse-header-color'
  | '@collapse-body-bg'
  | '@collapse-body-color'
  | '@date-picker-bg'
  | '@date-picker-heading-color'
  | '@date-picker-cell-color'
  | '@font-weight-base'
  | '@font-weight-strong'
  | '@font-size-base'
  | '@font-size-lg'
  | '@font-size-sm'
  | '@heading-1-size'
  | '@heading-2-size'
  | '@heading-3-size'
  | '@heading-4-size'
  | '@heading-5-size'
  | '@heading-font-weight'
  | '@heading-font-family'
  | '@heading-color'
  | '@heading-secondary-font-weight'
  | '@heading-secondary-font-family'
  | '@heading-secondary-color'
  | '@heading-transform'
  | '@heading-secondary-transform'
  | '@page-header-heading-font-family'
  | '@page-header-heading-font-weight'
  | '@page-header-heading-title'
  | '@page-header-padding-breadcrumb'
  | '@page-header-padding-vertical'
  | '@page-header-padding'
  | '@page-header-back-color'
  | '@text-color-secondary'
  | '@text-color'
  | '@text-color-inverse'
  | '@text-selection-bg'
  | '@typography-title-font-weight'
  | '@link-color'
  | '@link-hover-color'
  | '@link-active-color'
  | '@link-decoration'
  | '@link-hover-decoration'
  | '@height-base'
  | '@height-lg'
  | '@checkbox-border-width'
  | '@checkbox-bg'
  | '@checkbox-color'
  | '@checkbox-check-bg'
  | '@checkbox-check-color'
  | '@checkbox-size'
  | '@radio-border-width'
  | '@radio-button-bg'
  | '@radio-button-checked-bg'
  | '@radio-button-color'
  | '@radio-dot-color'
  | '@radio-size'
  | '@form-item-label-font-size'
  | '@input-color'
  | '@input-label-color'
  | '@input-bg'
  | '@input-border-color'
  | '@input-border-radius'
  | '@input-disabled-bg'
  | '@input-disabled-color'
  | '@input-hover-border-color'
  | '@input-icon-color'
  | '@input-number-handler-bg'
  | '@input-height-sm'
  | '@input-height-base'
  | '@input-height-lg'
  | '@label-color'
  | '@calendar-item-active-bg'
  | '@menu-collapsed-width'
  | '@menu-dark-bg'
  | '@menu-dark-color'
  | '@menu-dark-highlight-color'
  | '@menu-dark-item-active-bg'
  | '@menu-dark-selected-item-icon-color'
  | '@menu-dark-selected-item-text-color'
  | '@menu-highlight-color'
  | '@menu-icon-margin-right'
  | '@menu-icon-size-lg'
  | '@menu-icon-size'
  | '@menu-bg'
  | '@menu-item-color'
  | '@menu-item-hover-bg'
  | '@menu-item-hover-color'
  | '@menu-item-active-bg'
  | '@menu-item-active-color'
  | '@menu-item-font-family'
  | '@menu-item-font-size'
  | '@menu-item-font-weight'
  | '@menu-item-height'
  | '@menu-item-padding'
  | '@menu-item-vertical-margin'
  | '@menu-item-text-transform'
  | '@notification-width'
  | '@outline-blur-size'
  | '@outline-width'
  | '@pagination-item-size'
  | '@picker-basic-cell-active-with-range-color'
  | '@picker-panel-cell-height'
  | '@picker-panel-cell-width'
  | '@popover-bg'
  | '@list-item-meta-description-font-size'
  | '@list-item-meta-title-font-family'
  | '@list-item-meta-title-color'
  | '@list-item-meta-description-font-family'
  | '@list-item-meta-description-color'
  | '@page-header-back-color'
  | '@tooltip-arrow-color'
  | '@tooltip-bg'
  | '@tooltip-color'
  | '@list-item-bg'
  | '@list-item-color'
  | '@list-item-hover-bg'
  | '@list-item-hover-color'
  | '@list-item-active-bg'
  | '@list-item-active-color'
  | '@table-bg'
  | '@table-header-bg'
  | '@table-header-color'
  | '@table-border-color'
  | '@table-expand-icon-bg'
  | '@table-expand-icon-color'
  | '@table-row-bg'
  | '@table-row-color'
  | '@table-row-hover-bg'
  | '@table-row-hover-color'
  | '@modal-bg'
  | '@modal-close-color'
  | '@modal-footer-bg'
export const DefaultTheme = {
  '@ant-prefix': 'ant',
  '@pv-prefix': 'pv',
  '@colors-gray-1': 'rgb(255, 255, 255)',
  '@colors-gray-2': 'rgb(248, 248, 248)',
  '@colors-gray-3': 'rgb(207, 207, 211)',
  '@colors-gray-4': 'rgb(183, 183, 189)',
  '@colors-gray-5': 'rgb(159, 158, 166)',
  '@colors-gray-6': 'rgb(134, 134, 144)',
  '@colors-gray-7': 'rgb(110, 109, 121)',
  '@colors-gray-8': 'rgb(85, 85, 94)',
  '@colors-gray-9': 'rgb(59, 59, 64)',
  '@colors-gray-10': 'rgb(33, 34, 36)',
  '@colors-green-0': 'rgb(207, 239, 228)',
  '@colors-green-1': 'rgb(206, 238, 227)',
  '@colors-green-2': 'rgb(177, 216, 201)',
  '@colors-green-3': 'rgb(148, 190, 174)',
  '@colors-green-4': 'rgb(122, 164, 147)',
  '@colors-green-5': 'rgb(87, 128, 110)',
  '@colors-green-6': 'rgb(76, 118, 100)',
  '@colors-green-7': 'rgb(56, 101, 81)',
  '@colors-green-8': 'rgb(37, 84, 62)',
  '@colors-green-9': 'rgb(21, 69, 47)',
  '@colors-green-10': 'rgb(11, 57, 34)',
  '@screen-lg-max': '(@screen-xl - 1px)',
  '@screen-lg-min': '992px',
  '@screen-lg': '992px',
  '@screen-md-max': '(@screen-lg - 1px)',
  '@screen-md-min': '768px',
  '@screen-md': '768px',
  '@screen-sm-max': '(@screen-md - 1px)',
  '@screen-sm-min': '576px',
  '@screen-sm': '576px',
  '@screen-xl-max': '(@screen-xxl - 1px)',
  '@screen-xl-min': '1200px',
  '@screen-xl': '1200px',
  '@screen-xs-max': '(@screen-sm - 1px)',
  '@screen-xs-min': '375px - 1px',
  '@screen-xs': '375px',
  '@screen-xxl-max': '(@screen-xxxl - 1px)',
  '@screen-xxl-min': '1600px',
  '@screen-xxl': '1600px',
  '@screen-xxxl-min': '1920px',
  '@screen-xxxl': '1920px',
  '@animation-duration-base': '0.2s',
  '@animation-duration-slow': '0.3s',
  '@animation-duration-fast': '0.1s',
  '@primary-color': '#57806e',
  '@success-color': '#ceeee3',
  '@info-color': '#2db7f5',
  '@error-color': '#e06c51',
  '@warning-color': '#fce3a4',
  '@danger-color': '#d2381b',
  '@accent-color': '#fdf6e4',
  '@white': '#ffffff',
  '@disabled-color': '#cfcfd3',
  '@font-family-primary': "'Jost', sans-serif",
  '@font-family-secondary': "'Roboto', sans-serif",
  '@font-family-tertiary': "'Roboto', sans-serif",
  '@border-width-base': '1px',
  '@border-style-base': 'solid',
  '@border-radius-base': '4px',
  '@border-radius-sm': '4px',
  '@border-color-inverse': '#ffffff',
  '@border-color-base': '#e8e7e9',
  '@border-color-focused': '#868690',
  '@border-radius-lg': '16px',
  '@border-radius-md': '8px',
  '@border-radius-xs': '2px',
  '@alert-error-bg-color': 'var(--error-color)',
  '@alert-message-color': 'var(--text-color)',
  '@alert-error-border-color': 'var(--error-color)',
  '@alert-error-icon-color': '#ffffff',
  '@alert-error-message-color': '#ffffff',
  '@alert-info-bg-color': '#fff6e8',
  '@alert-info-border-color': '#fff6e8',
  '@alert-info-icon-color': '#cf8516',
  '@alert-info-message-color': '#cf8516',
  '@alert-success-bg-color': 'rgb(206, 238, 227)',
  '@alert-success-border-color': 'rgb(206, 238, 227)',
  '@alert-success-icon-color': 'rgb(21, 69, 47)',
  '@alert-success-message-color': 'rgb(21, 69, 47)',
  '@alert-warning-bg-color': 'var(--warning-color)',
  '@alert-warning-border-color': 'var(--warning-color)',
  '@alert-warning-icon-color': 'var(--text-color-inverse)',
  '@alert-warning-message-color': 'var(--text-color-inverse)',
  '@alert-with-description-icon-size': '24px',
  '@toast-success-bg': 'rgb(206, 238, 227)',
  '@toast-success-title-color': 'rgb(21, 69, 47)',
  '@toast-success-message-color': 'rgb(21, 69, 47)',
  '@toast-success-icon-color': 'rgb(21, 69, 47)',
  '@toast-error-bg': 'var(--error-color)',
  '@toast-error-title-color': '#ffffff',
  '@toast-error-message-color': '#ffffff',
  '@toast-error-icon-color': '#ffffff',
  '@toast-warning-bg': 'var(--warning-color)',
  '@toast-warning-title-color': 'var(--text-color-inverse)',
  '@toast-warning-message-color': 'var(--text-color-inverse)',
  '@toast-warning-icon-color': 'var(--text-color-inverse)',
  '@toast-info-bg': '#fff6e8',
  '@toast-info-title-color': '#cf8516',
  '@toast-info-message-color': '#cf8516',
  '@toast-info-icon-color': '#cf8516',
  '@drawer-body-padding': '40px',
  '@drawer-bg': 'var(--body-background)',
  '@drawer-header-close-size': '48px',
  '@drawer-header-close-bg': '#ffffff',
  '@drawer-header-close-color': 'var(--text-color)',
  '@drawer-header-close-hover-color': 'var(--text-color)',
  '@drawer-header-close-hover-bg': '#ffffff',
  '@avatar-bg': 'var(--primary-color)',
  '@avatar-color': 'var(--text-color)',
  '@icon-color': 'inherit',
  '@badge-count-bg': 'var(--primary-color)',
  '@badge-count-color': 'var(--text-color-inverse)',
  '@badge-color': 'var(--primary-color)',
  '@badge-font-family': 'var(--font-family-primary)',
  '@badge-font-size': '14px',
  '@tag-default-bg': '#e7e9e8',
  '@tag-default-color': 'var(--text-color-secondary)',
  '@tag-font-size': '12px',
  '@tag-font-family': 'var(--font-family-primary)',
  '@tabs-font-family': 'var(--font-family-secondary)',
  '@tabs-font-size': 'var(--font-size-base)',
  '@tabs-active-color': 'var(--primary-color)',
  '@background-color-base': '#ffffff',
  '@body-background': '#ffffff',
  '@layout-body-background': '#ffffff',
  '@layout-footer-background': 'transparent',
  '@layout-footer-height': '112px',
  '@layout-footer-padding': '40px',
  '@layout-header-background': 'transparent',
  '@layout-header-border-color': '#e8e7e9',
  '@layout-header-color': 'var(--text-color)',
  '@layout-header-height': '112px',
  '@layout-header-mobile-height': '80px',
  '@layout-header-padding': '48px 40px 24px 40px',
  '@layout-section-padding-horizontal': '40px',
  '@layout-section-padding-vertical': '40px',
  '@layout-section-padding': '40px',
  '@layout-section-title-font-family': 'var(--font-family-secondary)',
  '@layout-sider-background': 'var(--text-color)',
  '@layout-sider-width': '280px',
  '@layout-sider-collapsed-width': '112px',
  '@cart-icon-bg': '#212224',
  '@cart-icon-color': '#ffffff',
  '@breadcrumb-base-color': 'rgb(159, 158, 166)',
  '@breadcrumb-font-size': '12px',
  '@breadcrumb-font-family': 'var(--font-family-secondary)',
  '@breadcrumb-last-item-color': 'rgb(110, 109, 121)',
  '@breadcrumb-link-color-hover': 'var(--text-color-secondary)',
  '@breadcrumb-link-color': 'rgb(159, 158, 166)',
  '@breadcrumb-separator-color': 'rgb(159, 158, 166)',
  '@btn-font-family': 'var(--font-family-primary)',
  '@btn-font-weight': '700',
  '@btn-text-transform': 'uppercase',
  '@btn-default-bg': 'rgb(207, 207, 211)',
  '@btn-default-color': 'var(--text-color-secondary)',
  '@btn-default-border': 'rgb(207, 207, 211)',
  '@btn-default-disabled-color': 'rgb(255, 255, 255)',
  '@btn-default-hover-color': 'var(--text-color-secondary)',
  '@btn-default-hover-bg': 'rgb(159, 158, 166)',
  '@btn-default-hover-border': 'rgb(159, 158, 166)',
  '@btn-ghost-bg': 'transparent',
  '@btn-ghost-border': '#e8e7e9',
  '@btn-ghost-color': 'var(--text-color-secondary)',
  '@btn-ghost-hover-bg': 'transparent',
  '@btn-ghost-hover-color': 'var(--text-color-secondary)',
  '@btn-ghost-hover-border': '#e8e7e9',
  '@btn-disable-bg': 'rgb(248, 248, 248)',
  '@btn-disable-color': 'rgb(183, 183, 189)',
  '@btn-disable-border': '#e8e7e9',
  '@btn-border-radius': '4px',
  '@btn-font-size-lg': '14px',
  '@btn-font-size': 'var(--font-size-base)',
  '@btn-height-base': '48px',
  '@btn-height-lg': '48px',
  '@btn-primary-bg': 'var(--primary-color)',
  '@btn-primary-color': '#fff',
  '@btn-primary-border-color': 'var(--btn-primary-bg)',
  '@btn-primary-hover-color': '#ffffff',
  '@btn-primary-hover-bg': 'rgba(87, 128, 110, 0.85)',
  '@btn-primary-hover-border-color': 'rgba(87, 128, 110, 0.85)',
  '@btn-square-only-icon-size': '16px',
  '@card-bg': '#fffdfa',
  '@card-selected-bg': 'rgb(248, 248, 248)',
  '@card-hoverable-hover-border': '#e8e7e9',
  '@card-hoverable-hover-shadow': '0px 0px 31px rgba(0, 0, 0, 0.06)',
  '@card-badge-bg': 'var(--primary-color)',
  '@card-badge-color': 'var(--text-color-inverse)',
  '@card-shadow': '0 0 1px rgb(207, 207, 211)',
  '@card-subtitle-font-family': 'var(--font-family-secondary)',
  '@card-meta-font-family': 'var(--font-family-secondary)',
  '@card-blur-bg-transparency': '0.75',
  '@component-background': '#ffffff',
  '@component-color': 'var(--text-color-secondary)',
  '@collapse-header-bg': 'var(--card-bg)',
  '@collapse-header-color': 'var(--text-color)',
  '@collapse-body-bg': '#ffffff',
  '@collapse-body-color': 'var(--text-color)',
  '@date-picker-bg': 'var(--input-bg)',
  '@date-picker-heading-color': 'var(--text-color-secondary)',
  '@date-picker-cell-color': 'var(--text-color)',
  '@font-weight-base': '400',
  '@font-weight-strong': '700',
  '@font-size-base': '14px',
  '@font-size-lg': '14px',
  '@font-size-sm': '12px',
  '@heading-1-size': '36px',
  '@heading-2-size': '28px',
  '@heading-3-size': '22px',
  '@heading-4-size': '20px',
  '@heading-5-size': '16px',
  '@heading-font-weight': '800',
  '@heading-font-family': 'var(--font-family-primary)',
  '@heading-color': 'var(--text-color)',
  '@heading-secondary-font-weight': '600',
  '@heading-secondary-font-family': 'var(--font-family-secondary)',
  '@heading-secondary-color': 'var(--text-color)',
  '@heading-transform': 'uppercase',
  '@heading-secondary-transform': 'uppercase',
  '@page-header-heading-font-family': 'var(--font-family-primary)',
  '@page-header-heading-font-weight': '900',
  '@page-header-heading-title': '36px',
  '@page-header-padding-breadcrumb': '0',
  '@page-header-padding-vertical': '0',
  '@page-header-padding': '0',
  '@page-header-back-color': '#000',
  '@text-color-secondary': '#6e6d79',
  '@text-color': '#212224',
  '@text-color-inverse': '#ffffff',
  '@text-selection-bg': 'var(--text-color)',
  '@typography-title-font-weight': '800',
  '@link-color': 'var(--primary-color)',
  '@link-hover-color': 'rgba(var(--primary-color-rgb), 0.85)',
  '@link-active-color': 'rgba(var(--primary-color-rgb), 0.85)',
  '@link-decoration': 'none',
  '@link-hover-decoration': 'none',
  '@height-base': '48px',
  '@height-lg': '48px',
  '@checkbox-border-width': '1px',
  '@checkbox-bg': 'var(--input-bg)',
  '@checkbox-color': 'var(--text-color)',
  '@checkbox-check-bg': 'var(--text-color)',
  '@checkbox-check-color': 'var(--text-color-inverse)',
  '@checkbox-size': '16px',
  '@radio-border-width': '1px',
  '@radio-button-bg': 'var(--text-color-inverse)',
  '@radio-button-checked-bg': 'var(--text-color-inverse)',
  '@radio-button-color': 'var(--text-color-inverse)',
  '@radio-dot-color': '#212224',
  '@radio-size': '16px',
  '@form-item-label-font-size': '12px',
  '@input-color': 'var(--text-color)',
  '@input-label-color': 'var(--input-color)',
  '@input-bg': '#ffffff',
  '@input-border-color': '#e8e7e9',
  '@input-border-radius': '4px',
  '@input-disabled-bg': '#f8f8f8',
  '@input-disabled-color': 'var(--input-color)',
  '@input-hover-border-color': 'rgb(85, 85, 94)',
  '@input-icon-color': 'var(--text-color)',
  '@input-number-handler-bg': '#ffffff',
  '@input-height-sm': '36px',
  '@input-height-base': '48px',
  '@input-height-lg': '48px',
  '@label-color': 'var(--text-color-secondary)',
  '@calendar-item-active-bg': '#57806e',
  '@menu-collapsed-width': '112px',
  '@menu-dark-bg': 'transparent',
  '@menu-dark-color': 'var(--text-color-inverse)',
  '@menu-dark-highlight-color': 'var(--menu-highlight-color)',
  '@menu-dark-item-active-bg': 'transparent',
  '@menu-dark-selected-item-icon-color': 'var(--menu-highlight-color)',
  '@menu-dark-selected-item-text-color': 'var(--text-color-inverse)',
  '@menu-highlight-color': 'var(--primary-color)',
  '@menu-icon-margin-right': '16px',
  '@menu-icon-size-lg': '24px',
  '@menu-icon-size': '16px',
  '@menu-bg': 'var(--text-color)',
  '@menu-item-color': 'var(--text-color-inverse)',
  '@menu-item-hover-bg': 'transparent',
  '@menu-item-hover-color': 'var(--primary-color)',
  '@menu-item-active-bg': 'var(--primary-color)',
  '@menu-item-active-color': 'var(--text-color-inverse)',
  '@menu-item-font-family': 'var(--font-family-primary)',
  '@menu-item-font-size': '15px',
  '@menu-item-font-weight': '700',
  '@menu-item-height': '48px',
  '@menu-item-padding': '48px / 2 40px',
  '@menu-item-vertical-margin': '8px',
  '@menu-item-text-transform': 'uppercase',
  '@notification-width': '420px',
  '@outline-blur-size': '0',
  '@outline-width': '0',
  '@pagination-item-size': '36px',
  '@picker-basic-cell-active-with-range-color': 'rgba(206, 238, 227, 0.3)',
  '@picker-panel-cell-height': '40px',
  '@picker-panel-cell-width': '40px',
  '@popover-bg': '#ffffff',
  '@list-item-meta-description-font-size': '1em',
  '@list-item-meta-title-font-family': 'var(--font-family-secondary)',
  '@list-item-meta-title-color': 'var(--text-color-secondary)',
  '@list-item-meta-description-font-family': 'var(--font-family-secondary)',
  '@list-item-meta-description-color': 'var(--text-color-secondary)',
  '@tooltip-arrow-color': 'var(--primary-color)',
  '@tooltip-bg': 'var(--primary-color)',
  '@tooltip-color': '#ffffff',
  '@list-item-bg': '#ffffff',
  '@list-item-color': 'var(--text-color)',
  '@list-item-hover-bg': '#f0f0f0',
  '@list-item-hover-color': 'var(--text-color)',
  '@list-item-active-bg': '#e9e9e9',
  '@list-item-active-color': 'var(--text-color)',
  '@table-bg': 'var(--component-background)',
  '@table-header-bg': '#f8f8f8',
  '@table-header-color': 'var(--text-color)',
  '@table-border-color': '#e8e7e9',
  '@table-expand-icon-bg': 'rgb(207, 207, 211)',
  '@table-expand-icon-color': 'white',
  '@table-row-bg': 'var(--body-background)',
  '@table-row-color': 'var(--text-color)',
  '@table-row-hover-bg': 'rgb(248, 248, 248)',
  '@table-row-hover-color': 'var(--text-color)',
  '@modal-bg': '#ffffff',
  '@modal-close-color': '#212224',
  '@modal-footer-bg': '#ffffff',
}
