import { MouseEventHandler, useEffect, useState } from 'react';

const MoveDragThreshold = 10;

export function useDragDetection(): {
  handleMouseDown: () => void;
  handleChildClick: MouseEventHandler;
  dragging: boolean;
} {
  const [mouseDown, setMouseDown] = useState(false);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    let mouseMove = 0;

    function handleMouseUp(): void {
      setMouseDown(false);
      setDragging(false);
      mouseMove = 0; // Reset mouseMove after mouse up
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    }

    function handleMouseMove(e: MouseEvent): void {
      if (dragging) return; // Dragging already detected
      mouseMove += Math.abs(e.movementX) + Math.abs(e.movementY);
      if (mouseMove > MoveDragThreshold) {
        setDragging(true);
      }
    }

    if (mouseDown) {
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseDown, dragging]);

  function handleMouseDown(): void {
    setMouseDown(true);
    setDragging(false);
  }

  const handleChildClick: MouseEventHandler = (e) => {
    if (dragging) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return {
    handleMouseDown,
    handleChildClick,
    dragging,
  };
}
