import { FC, Fragment, useCallback, useState } from 'react'

import { Flex } from 'antd'

import { useLoading } from '@data-client/hooks'

import { Button } from '@peoplevine/sdk/components/button/Button'
import Form, { Item, OneTimeCode } from '@peoplevine/sdk/components/form'
import { VerticalSpace } from '@peoplevine/sdk/components/layout'
import { StepsSlider } from '@peoplevine/sdk/components/steps/StepsSlider'
import Text from '@peoplevine/sdk/components/text/Text'
import { Title } from '@peoplevine/sdk/components/text/Title'

import ActivateAccount from '../ActivateAccount'
import { useActivate } from '../ActivateProvider'

interface IVerifySMSCode {
  customerId: number
}

const VerifySMSCode: FC<IVerifySMSCode> = ({ customerId }) => {
  const CODE_LENGTH = 5
  const { verifyCode, restartActivation } = useActivate()
  const [code, setCode] = useState('')
  const [activeStep, setActiveStep] = useState(0)

  const handleVerifyCode = useCallback(async () => {
    await verifyCode({ code, customerId }).then((response) => {
      response && response.success && setActiveStep(1)
    })
  }, [code, customerId, verifyCode])

  const [verify, verifying] = useLoading(handleVerifyCode, [code])

  return (
    <Fragment>
      <StepsSlider
        active={activeStep}
        elements={[
          <VerticalSpace key={0} size={16}>
            <Title level={1} style={{ marginBottom: 0 }}>
              Activate Account
            </Title>
            <Flex vertical gap={2}>
              <Text>We've sent a 5-digit code to your mobile number</Text>
              <Flex gap={2} align={'center'}>
                <Text>Having Trouble?</Text>
                <Button style={{ width: 'auto' }} onClick={restartActivation} type={'link'}>
                  Resend activation code
                </Button>
              </Flex>
            </Flex>
            <Form onFinish={verify}>
              <Flex vertical gap={12}>
                <Item>
                  <OneTimeCode length={5} onChange={setCode} disabled={verifying} />
                </Item>
                <Item>
                  <Button
                    loading={verifying}
                    block
                    htmlType={'submit'}
                    disabled={!(code && code.length === CODE_LENGTH)}
                  >
                    Verify code
                  </Button>
                </Item>
              </Flex>
            </Form>
          </VerticalSpace>,
          <ActivateAccount key={1} code={code} customerId={customerId} />,
        ]}
      />
    </Fragment>
  )
}

export default VerifySMSCode
