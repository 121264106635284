import { ApiEndpoint } from './api/endpoint'
import { ApiEntity } from './api/entity'

export type VerifyMethod = 'email' | 'sms'

export type VerifyResponse = {
  readonly id: number
  readonly success: boolean
  readonly sendMethod: VerifyMethod
  readonly code: string
  readonly message: string
}

interface VerifyActivationCode {
  readonly code: string
  readonly customerId?: Data.ID
}

class VerifyEntity extends ApiEntity {
  static readonly urlRoot = `/api/public/verify`
  readonly id: string = ''
  readonly code: string = ''
  readonly success: boolean = false
  readonly message: string = ''
}

const verifyEmail = new ApiEndpoint({
  path: '/api/public/verify/email/:code',
  method: 'GET',
  process: (value) => value as VerifyEntity,
})

const resendEmailVerify = new ApiEndpoint({
  path: '/api/public/verify/email',
  method: 'GET',
  searchParams: {} as {
    email: string
    verifyCallbackUrl?: '/verify-email'
  },
  process: (value) => value as VerifyEntity,
})

const sendActivation = new ApiEndpoint({
  path: '/api/public/verify/activate',
  method: 'POST',
  body: {} as {
    customerId: Data.ID
    method: VerifyMethod
    callback?: string
  },
  process: (value) => value as VerifyEntity,
})

const verifyActivationCode = new ApiEndpoint({
  path: '/api/public/verify/activate',
  method: 'GET',
  searchParams: {} as VerifyActivationCode,
  process: (value) => value as VerifyResponse
})

const VerifyResource = {
  email: {
    send: resendEmailVerify,
    verify: verifyEmail,
  },
  activation: {
    send: sendActivation,
    verify: verifyActivationCode,
  },
}

export { VerifyEntity, VerifyResource }
