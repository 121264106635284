import { FC, PropsWithChildren } from 'react'

import { Badge, Flex } from 'antd'

import { useColor } from '../app'

export type BadgeProps = PropsWithChildren<{
  type: SDK.Components.ColorVariant
  responsive: boolean
}>

export const CardBadge: FC<Partial<BadgeProps>> = ({ type = 'primary', children }) => {
  const { token, bgByVariant, textByBgVariant } = useColor()

  return (
    <Badge
      styles={{
        root: {
          height: 64,
          width: 64,
          color: textByBgVariant('primary'),
          fontFamily: token.fontFamilySecondary,
          textTransform: 'uppercase',
          fontWeight: 800,
        },
      }}
    >
      <Flex
        vertical
        align={'center'}
        justify={'center'}
        style={{
          height: '100%',
          width: '100%',
          alignContent: 'center',
          color: textByBgVariant('primary'),
          fontFamily: token.fontFamilySecondary,
          textTransform: 'uppercase',
          fontWeight: 800,
          fontSize: 16,
          borderRadius: token.borderRadius / 2,
          backgroundColor: children && bgByVariant(type),
        }}
      >
        {children}
      </Flex>
    </Badge>
  )
}
