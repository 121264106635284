import { FC, PropsWithChildren, createContext, useContext, useEffect } from 'react'
import { useKeyPress, useToggle } from 'react-use'

import { Drawer } from 'antd'

import { useController } from '@data-client/react'

import { DeploymentsResource } from 'src/datasource/domain'

import { DeveloperAuthProvider } from './DebugAuth'
import DeveloperWindow from './DeveloperWindow'
import DeveloperProps from './tabs/DebugProps'

type DeveloperProps = {
  switchDomain: (id: number) => Promise<void>
}

const defaultValues: DeveloperProps = {
  switchDomain: () => Promise.resolve(),
}

const Develop = createContext(defaultValues)

const Provider: FC<PropsWithChildren> = ({ children }) => {
  const controller = useController()

  const switchDomain = async (id: number) => {
    const deployment = await controller.fetch(DeploymentsResource.deployment, { id: `${id}` })
    await fetch(`/api/admin/switch`, {
      method: 'POST',
      body: JSON.stringify({
        REACT_APP_DOMAIN: deployment.host,
        REACT_APP_GLOBAL_NETWORK_ID: deployment.globalNetworkId,
        REACT_APP_API_SIGNATURE: deployment.signature,
        REACT_APP_PUBLIC_TOKEN: deployment.token,
      }),
    })
    controller.expireAll({ testKey: () => true })
  }

  return (
    <DeveloperAuthProvider>
      <Develop.Provider value={{ switchDomain }}>{children}</Develop.Provider>
    </DeveloperAuthProvider>
  )
}

const DeveloperLayout: FC = () => {
  const [pressed] = useKeyPress('Escape')
  const [active, toggle] = useToggle(false)

  useEffect(() => {
    pressed && toggle()
  }, [pressed, toggle])

  return process.env.NODE_ENV === 'development' ? (
    <Provider>
      <Drawer closeIcon={true} open={active} closable={true} onClose={toggle} placement={'right'} size={'large'}>
        <DeveloperWindow />
      </Drawer>
    </Provider>
  ) : null
}

const useDeveloper: () => DeveloperProps = () => useContext(Develop)

const DeveloperProvider = () => {
  return process.env.NODE_ENV === 'development' ? <DeveloperLayout /> : null
}

export { DeveloperLayout, DeveloperProvider, useDeveloper }
