import { ColorKeys } from '../themes/default'
import { createApiResource } from './api/endpoint'
import { ApiEntity } from './api/entity'
import { PortalConfig, PropsConfig } from './config'

export type DomainFilter = {
  orderBy?: string
  search?: string
  type?: 'portal' | 'apply' | 'commerce'
} & Partial<Data.Paginated>

export type PortalDeploymentFilter = {
  search?: string
  stage?: 'qa' | 'stage' | 'production'
}

class PortalDeployment extends ApiEntity {
  id: string
  host: string
  domainId: number
  signature: string
  token: string
  globalNetworkId?: number
  companyId: number
  state?: {
    healthStatus: string
    image: string
    lastUpdated: string
    stage: string
    syncStatus: string
  }
}

export class DomainConfig {
  id: Data.ID = 0
  domainId: Data.ID = 0
  plan: 'Lounge' | 'Plus' = 'Plus'
  debug: boolean = false
  environment: string = 'US'
  token: string = ''
  analytics: any
  pages: any
  props: PropsConfig = PropsConfig.fromJS()
  theme?: { [key in ColorKeys]?: string | number } = {}
  intl: any
  settings: any
}

class DomainEntity extends ApiEntity {
  static urlRoot = `/admin/api/domains`
  id: number = 0
  companyId: number = 0
  name: string = ''
  type: string = ''
  externalEmailId: string = ''
  externalDomainId: string = ''
  externalSslId: string = ''
  sslRegistered: boolean = false
  emailAuthenticated: boolean = false
  registered: boolean = false
  settings: any
  config?: DomainConfig
  portalConfig?: DomainConfig
  referenceType: string = ''
  referenceId: Data.ID = 0
  allowSsl: boolean = false
  fullUrl: string = ''
  default: boolean = false
  passwordProtected: boolean = false
  pageStyleId: Data.ID = 0
  paymentProcessorId: Data.ID = 0
  authenticatedPageStyleId: Data.ID = 0
  memberPageStyleId: Data.ID = 0
  environment: 'Production' | 'Stage' | 'QA' = 'Production'
  version: 3 | 4 = 4
}

const DomainBase = createApiResource({
  path: '/api/admin/domains/:id',
  schema: DomainEntity,
}).extend('config', {
  method: 'GET',
  path: '/dev/config/:domain',
  schema: PortalConfig
})
  .extend('deployment', {
    method: 'GET',
    path: '/api/admin/domains/:id/deployments',
    schema: PortalDeployment
  })

const DeploymentsResource = createApiResource({
  path: '/api/admin/domains/deployments/:id',
  schema: PortalDeployment,
}).extend('deployment', {
  method: 'GET',
  path: '/api/admin/domains/:id/deployments',
  schema: PortalDeployment
})

const DomainResource = {
  ...DomainBase,
}
export { DeploymentsResource, DomainEntity, DomainResource, PortalDeployment }

