import { Entity } from '@data-client/rest'

import { BaseAddress } from '../../address'
import { ImageEntity } from '../../media'
import { OrderEntity } from '../../orders/order'
import { TransactionRefundItem } from '../../transaction'
import { AccountTicket } from '../ticket'
import { AccountBooking } from './booking'

class AccountEventSummary extends AccountBooking {
  readonly summary: string = ''
  readonly venue: string = ''
  readonly venueAddress?: string
  readonly graphic: string = ''
  readonly photos: ImageEntity[] = []
  readonly type = 'event'
  readonly timezone_id: string = ''
  readonly uid: string = ''
  readonly registered: boolean = false
  readonly settings: API.EventSettings = {
    transferEnabled: false,
    cancelEnabled: false,
    socialEnabled: false,
    printEnabled: false,
    calendarEnabled: false,
    buttonText: '',
    callToAction: '',
    verb: '',
  }

  get activeTickets(): AccountTicket[] {
    return this.purchasedTickets.filter((t) => t.status !== 'cancelled' && t.status !== 'scanned')
  }

  get ticketQty(): number {
    return this.activeTickets ? this.activeTickets.length : 0
  }

  get transferredQty(): number {
    return this.activeTickets.filter((p) => p.transferred).length
  }

  get qty(): number {
    return this.ticketQty
  }

  get image(): string {
    return this.graphic
  }

  get locationName(): string {
    return this.venue
  }

  get address(): BaseAddress | undefined {
    const address = this.venueAddress
    if (!address) return undefined
    return BaseAddress.fromJS({
      address: this.venueAddress?.split(',')[0]?.trimStart() || '',
      city: address?.split(',')[1].trimStart() || '',
      state: address?.split(',')[2].trimStart() || '',
    })
  }
  static key = 'AccountEvent'
}

class CancelEventResponse extends Entity implements API.CancelBookingResponse {
  id: Data.ID
  static schema = {
    order: OrderEntity,
    orders: [OrderEntity],
    refunds: [TransactionRefundItem],
    booking: AccountEventSummary,
  }

  orderId?: Data.ID
  refunds?: TransactionRefundItem[]
  amount: number = 0
  message: string = ''
  success: boolean = false
  orders?: OrderEntity[]
  booking?: AccountEventSummary

  pk() {
    return `${this.orderId}`
  }
}

export { AccountEventSummary, CancelEventResponse }
