import { FC } from 'react'
import 'react-international-phone/style.css'

import { FormInstance, Input } from 'antd'

import validator from 'validator'

import { Item } from '..'
import { withPrefix } from '../../../providers/ConfigProvider'
import { FormProps } from '../FormItem'

type PhoneNumberProps = {
  form?: FormInstance
  onValid?: (clean: string, formatted: string) => void
  onChange?: (formatted: string, clean?: string) => void
} & FormProps

const PhoneNumber: FC<PhoneNumberProps> = ({
  disabled = false,
  placeholder = '(xxx) xxx-xxxx',
  errorMessage,
  enableTab = true,
  label = 'Phone Number',
  name = 'phoneNumber',
  className,
  rules,
  onValid,
  onChange,
  form,
  ...props
}) => {
  return (
    <Item
      label={label}
      name={name}
      required={props.required}
      rules={[
        {
          validator: (_, value) => {
            return value && validator.isMobilePhone(value, 'en-US') ? Promise.resolve() : Promise.reject()
          },
          required: props.required,
          message: errorMessage ?? 'Please enter a valid phone number',
        },
      ]}
      {...props}
    >
      <Input disabled={disabled} className={withPrefix('phone-form-input')} />
    </Item>
  )
}

export default PhoneNumber
