import { type FC, useState } from 'react'

import { Col, Row } from 'antd'

import { useController, useLoading } from '@data-client/react'

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { CreditCardResource, WalletResource } from 'src/api/account/wallet'
import { Button } from 'src/components/form'
import { CreditCardForm } from 'src/components/wallet/CreditCardForm'
import { CreditCardWallet } from 'src/datasource/wallet/wallet'
import { useI18n } from 'src/providers/I18n'

import { useStripeContext } from '.'

const DEFAULT_OPTIONS = {
  wallets: {
    applePay: 'never',
    googlePay: 'never',
  },
  fields: {
    billingDetails: {
      name: 'auto',
      email: 'auto',
      phone: 'auto',
      address: 'auto',
    },
  },
} as const

type AddPaymentMethodValues = {
  name: string
  billingUse?: API.WalletBillingUse
  nickname?: string
  billingDetails: Partial<API.WalletBillingAddress>
}

type AddPaymentMethodProps = {
  onSuccess: (creditCard: API.WalletItem<'creditcard'>) => void
} & Omit<SDK.Components.PaymentMethodFormProps, 'onSuccess'>

const AddPaymentMethodForm: FC<AddPaymentMethodProps> = ({ onSuccess, amount, billingUse }) => {
  const { fetch } = useController()
  const { money, t } = useI18n()
  const { id } = useStripeContext()
  const [valid, setValid] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const [handleSubmit, loading] = useLoading(
    (values: AddPaymentMethodValues) => {
      return new Promise<CreditCardWallet>(async (resolve, reject) => {
        if (!stripe || !elements) return reject(new Error('Stripe not loaded'))

        const { error: submitError } = await elements.submit()
        if (submitError) {
          return reject()
        }

        // Create setup intent for payment method via api
        const intent = await fetch(CreditCardResource.setupIntent, { id: id })

        // Confirm setup intent with stripe - If 3DS is required, it will be triggered
        const confirm = await stripe.confirmSetup({
          elements: elements,
          clientSecret: intent.clientSecret,
          confirmParams: {
            return_url: window.location.href,
          },
          redirect: 'if_required',
        })
        if (confirm.error) {
          console.log(new Error(confirm.error.message))
          return reject(new Error(confirm.error.message))
        }
        let paymentMethodId = confirm?.setupIntent?.payment_method

        return await fetch(WalletResource.cards.getList.push, {
          token: paymentMethodId,
          billingUse: typeof billingUse === 'string' ? billingUse : values.billingUse,
          nickname: values.nickname,
          paymentProcessorId: id,
          billingDetails: values.billingDetails,
        })
          .then((response) => {
            onSuccess(response)
            resolve(response)
          })
          .catch(reject)
      })
    },
    [stripe, elements, id, onSuccess, fetch],
  )

  return (
    <CreditCardForm onFinish={handleSubmit} loading={loading} disabled={!stripe || loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <CreditCardForm.FullName />
        </Col>
        <Col span={24}>
          <PaymentElement onChange={(event) => setValid(event.complete)} options={DEFAULT_OPTIONS} />
        </Col>
        <Col span={24}>
          <CreditCardForm.Nickname />
        </Col>
        {billingUse && typeof billingUse === 'boolean' && (
          <Col span={24}>
            <CreditCardForm.BillingUseCards />
          </Col>
        )}
        <Col span={24}>
          <Button htmlType={'submit'} disabled={!valid} loading={loading}>
            {amount && amount?.due > 0
              ? t('Pay {amount}', { amount: amount ? money(amount?.due, amount?.currency) : '' })
              : 'Add Credit Card'}
          </Button>
        </Col>
      </Row>
    </CreditCardForm>
  )
}

export { AddPaymentMethodForm }
