import { FC, PropsWithChildren, ReactNode, isValidElement } from 'react'
import { default as Wrapper, SkeletonProps as WrapperProps } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { List, ListProps, Table, TableColumnsType } from 'antd'

import { withPrefix } from '../../providers/ConfigProvider'
import { VerticalSpace } from '../layout'
import { ListItem, ListItemMeta } from '../list/ListItem'
import { DataGrid } from '../pagination/InfiniteGrid'
import { useResponsive } from '../screen'
import { Title } from '../text/Title'

const Skeleton: FC<PropsWithChildren<WrapperProps>> = ({ style, ...props }) => {
  return (
    <Wrapper width={props.width ?? 100} height={props.height ?? 15} style={{ maxWidth: '100%', ...style }} {...props} />
  )
}

export type SkeletonWrapperProps = {
  loading?: boolean
} & WrapperProps &
  PropsWithChildren

const SkeletonWrapper: FC<PropsWithChildren<SkeletonWrapperProps>> = ({ loading, children, ...props }) => {
  return loading ? <Wrapper width={props.width ?? 100} height={props.height ?? 15} {...props} /> : <>{children}</>
}

const SkeletonTag: FC<{ size?: 'small' | 'middle' | 'large' }> = ({ size = 'middle' }) => {
  return <Wrapper className={withPrefix('skeleton-tag', `skeleton-tag-${size}`)} />
}

const SkeletonAvatar: FC<SkeletonWrapperProps> = ({ children, width, height, ...props }) => {
  return (
    <div
      className={withPrefix('skeleton-avatar-wrapper', 'avatar', 'avatar-circle', 'avatar-image')}
      style={{ width: width ?? '100%', height: height ?? '100%' }}
    >
      <Wrapper {...props} />
    </div>
  )
}

type SkeletonListMetaSizeProps =
  | ReactNode
  | false
  | {
      width: number
      height: number
    }

type SkeletonListMetaProps = {
  avatar?: false | Pick<SDK.Components.AvatarProps, 'shape' | 'placeholder' | 'icon' | 'size' | 'background'>
  title?: SkeletonListMetaSizeProps
  description?: SkeletonListMetaSizeProps
  extra?: SkeletonListMetaSizeProps
}

const SkeletonListMeta = ({
  avatar,
  title = {
    width: 100,
    height: 16,
  },
  description = {
    width: 150,
    height: 12,
  },
  extra = {
    width: 10,
    height: 12,
  },
}: SkeletonListMetaProps) => {
  const renderSkeleton = (props: SkeletonListMetaSizeProps) => {
    if (!props) return null
    if (isValidElement(props)) return props
    if (typeof props === 'object' && 'width' in props && 'height' in props) {
      return <Wrapper width={props.width} height={props.height} />
    }
    return null
  }

  return (
    <ListItemMeta
      avatar={
        avatar !== false
          ? { placeholder: avatar?.icon ? undefined : 'empty', shape: 'circle', size: 48, ...avatar }
          : undefined
      }
      title={renderSkeleton(title)}
      description={renderSkeleton(description)}
      extra={renderSkeleton(extra)}
    />
  )
}

type SkeletonListProps = {
  count: number
  bordered: boolean
  renderItem: ReactNode
} & SkeletonListMetaProps

const SkeletonList: FC<Partial<SkeletonListProps>> = ({ count = 3, bordered = true, renderItem, ...props }) => {
  return (
    <List
      dataSource={new Array(count).fill(0)}
      renderItem={(_, index) => (
        <ListItem bordered={bordered} key={index}>
          {renderItem ? renderItem : <SkeletonListMeta {...props} />}
        </ListItem>
      )}
    />
  )
}

type SkeletonTableProps = {
  columns: TableColumnsType<any>
} & Partial<SkeletonListProps>

const SkeletonTable = ({ count = 1, columns }: SkeletonTableProps) => (
  <Table
    dataSource={new Array(count).fill(0).map((_, i) => ({ id: i }))}
    rowKey={'id'}
    columns={columns.map((column) => ({
      ...column,
      render: () => <Wrapper height={24} width={'50%'} />,
    }))}
  />
)

type SkeletonGridProps = {
  count?: number
  skeleton?: ReactNode
  loading?: boolean
  grid?: ListProps<Data.Identified>['grid'] & { gutter: number | [number, number] }
}

const SkeletonGrid: FC<PropsWithChildren<SkeletonGridProps>> = ({ loading = true, children, count: defaultCount }) => {
  const { gridSize } = useResponsive()
  const count = defaultCount ?? gridSize
  const data = new Array<Data.Identified>(count)
    .fill({ id: '' })
    .map((_, index) => ({ id: `${index}`, pk: () => `${index}` }))
    .flat()
  return loading ? (
    <VerticalSpace size={16}>
      <Title level={2}>
        <SkeletonWrapper height={28} />
      </Title>
      <DataGrid data={data} />
    </VerticalSpace>
  ) : (
    children
  )
}

export {
  Skeleton,
  SkeletonAvatar,
  SkeletonGrid,
  SkeletonList,
  SkeletonListMeta,
  SkeletonTable,
  SkeletonTag,
  SkeletonWrapper,
}
