import ApiEntity from '../../api/entity'
import { OrderEntity } from '../../orders/order'
import { TransactionRefundItem } from '../../transaction'
import { AccountBooking } from './booking'

class AccountAppointment extends AccountBooking {
  readonly type: API.ScheduleType = 'appointment'
}

export class CancelAppointmentResponse
  extends ApiEntity
  implements API.CancelBookingResponse<OrderEntity, TransactionRefundItem, AccountAppointment> {
  static schema = {
    order: OrderEntity,
    refunds: [TransactionRefundItem],
    booking: AccountAppointment,
  }
  orderId?: Data.ID
  order?: OrderEntity
  refunds?: TransactionRefundItem[]
  amount: number
  message: string
  success: boolean
  booking?: AccountAppointment

  static key = 'AccountAppointment'
}

export { AccountAppointment }
