import { RcFile } from 'antd/lib/upload/interface'

export const toBase64 = (file: RcFile): Promise<string | void> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(String(reader.result))
    reader.onerror = (event) => {
      console.error(event)
      return reject('')
    }
  })
}
