import { ApiEndpoint, createApiResource } from './api/endpoint'
import { Singleton } from './api/entity'
import { AbstractTokenEntity } from './token'

class AdminEntity extends Singleton {
  static readonly urlRoot = '/api/user'
  readonly businessLocationId: number = 0
  readonly employeeId: string = ''
  readonly welcomeMessage: string = ''
  readonly lastAccessed: string = ''
  readonly companyId: number = 0
  readonly userId: number = 0
  readonly firstName: string = ''
  readonly lastName: string = ''
  readonly mobileNumber: string = ''
  readonly email: string = ''
  readonly username: string = ''
  readonly password: string = ''
  readonly permissionLevel: string = ''
  readonly status: string = ''
  readonly createdOn: string = ''
  readonly modifiedOn: string = ''
  readonly createdBy: number = 0
}

class AdminTokenEntity extends AbstractTokenEntity {
  static schema = {
    user: AdminEntity
  }
  readonly userId: number = 0
  readonly user: AdminEntity = AdminEntity.fromJS()
}

const AdminBase = createApiResource({
  path: '/api/admin/users/:id?',
  schema: AdminEntity,
})

const login = new ApiEndpoint({
  path: '/api/token',
  method: 'POST',
  body: {} as API.LoginRequest<'password'>,
  schema: AdminTokenEntity,
})

const switchCompany = new ApiEndpoint({
  path: '/api/token',
  method: 'POST',
  body: {} as API.LoginRequest<'access_token'>,
  schema: AdminTokenEntity,
})

const switchDomain = new ApiEndpoint({
  path: '/api/admin/domain/select/:id',
  method: 'POST',
  body: undefined,
  schema: AdminEntity,
})


const AdminResource = {
  ...AdminBase,
  login,
  switchCompany,
  switchDomain
}

export { AdminEntity, AdminResource, AdminTokenEntity }
