import { MutateEndpoint, RestGenerics } from '@data-client/rest'
import { ApiEndpoint } from './endpoint'

export class PaymentEndpoint<O extends RestGenerics = any> extends ApiEndpoint<O> {}

export function CreatePaymentEndpoint<O extends MutateEndpoint>({
  path,
  schema,
  body,
}: {
  readonly path: O['path']
  readonly schema: O['schema']
  readonly body?: O['body']
}) {
  return new PaymentEndpoint({
    path: path,
    method: 'POST',
    schema: schema,
    body: body ?? ({} as API.PaymentRequest),
  })
}
