import {
  FC,
  type FunctionComponent,
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Col, FormInstance, FormProps, Input, type InputProps, Row, Select, SelectProps } from 'antd'

import { useDLE } from '@data-client/react'

import validator from 'validator'

import { Country as CountryEntity, IntlResource } from '../../datasource/intl'
import { ShippingAddress } from '../../datasource/shipping'
import { useI18n } from '../../providers'
import { Item } from '../form'
import { FormRuleProps } from '../form/Form'
import IvyIcon from '../icon'

const formRules: FormRuleProps<ShippingAddress> = {
  address: [
    {
      required: true,
      message: 'Please enter a valid address',
    },
  ],
  address2: [
    {
      required: false,
    },
  ],
  city: [
    {
      required: true,
      message: 'Please enter a valid city',
    },
  ],
  state: [
    {
      required: true,
      message: 'Please select a state',
    },
  ],
  zipCode: [
    {
      required: true,
      message: 'Please enter a valid zip code',
    },
  ],
  country: [
    {
      required: true,
    },
  ],
  notes: [
    {
      required: false,
    },
  ],
}

type InputName = string | string[]

type AddressItemProps = {
  required?: boolean
  name?: InputName
}

const CountryContext = createContext<{
  country: CountryEntity
  onChange: (country: CountryEntity) => void
  form?: FormInstance
}>({
  onChange: () => {},
  country: CountryEntity.fromJS({ abbreviation: 'US' }),
})

interface Country extends FunctionComponent<PropsWithChildren> {
  ZipCode: FC<InputProps>
  Select: FC<SelectProps>
}

type StateComponent = FunctionComponent<AddressItemProps> & {
  Select: FC<SelectProps<string>>
  Input: FC<InputProps>
}

const State: StateComponent = ({ required, name = 'state' }: AddressItemProps) => {
  const { t } = useI18n()
  const { country } = useContext(CountryContext)
  return (
    <Item required={required} name={name} label={t('State')}>
      {country.abbreviation === 'US' ? <State.Select /> : <State.Input />}
    </Item>
  )
}

const StateSelect: FC<SelectProps<string>> = (props) => {
  const { data, loading } = useDLE(IntlResource.states)

  return (
    <Select<string>
      loading={loading}
      options={data?.map((it) => ({ label: it.name, value: it.abbreviation.toUpperCase() }))}
      suffixIcon={<IvyIcon type={'custom/chevron'} />}
      {...props}
    />
  )
}

const Country = ({ form, children }: { form?: FormInstance; children: ReactNode | ReactNode[] }) => {
  const [country, setCountry] = useState<CountryEntity>(CountryEntity.fromJS({ abbreviation: 'US' }))
  const onChange = (country: CountryEntity) => setCountry(country)
  return <CountryContext.Provider value={{ country, onChange, form }}>{children}</CountryContext.Provider>
}

const ZipCodeInput: FC<AddressItemProps> = ({ required = true, name = 'zipCode' }) => {
  const { t } = useI18n()
  const { country, form } = useContext(CountryContext)
  useEffect(() => {
    if (!form || !country || !country.postalValidator) return
    form.validateFields([name])
  }, [country])

  return (
    <Item
      label={t('Zip Code')}
      name={name}
      required={required}
      rules={[
        {
          required: required,
          validator: async (_, value) => {
            if (!required) return Promise.resolve()
            if (validator.isPostalCode(value, country.pk() as validator.PostalCodeLocale)) {
              return Promise.resolve()
            }
            return Promise.reject(t('Please enter a valid zip code'))
          },
          message: t('Please enter a valid zip code'),
        },
      ]}
    >
      <Input />
    </Item>
  )
}

const CountrySelect: FC<SelectProps<string>> = ({ ...props }) => {
  const { data = [], loading } = useDLE(IntlResource.countries)
  const { onChange } = useContext(CountryContext)
  return (
    <Select<string>
      loading={loading}
      onSelect={(value) => {
        onChange(data.find((it) => it.pk() === value) ?? CountryEntity.fromJS({ abbreviation: value }))
      }}
      options={data?.map((it) => ({ label: it.name, value: it.abbreviation.toUpperCase() }))}
      suffixIcon={<IvyIcon type={'custom/chevron'} />}
      {...props}
    />
  )
}

State.Input = (props) => <Input type={'text'} {...props} />

State.Select = StateSelect

Country.ZipCode = ZipCodeInput

Country.Select = CountrySelect

Country.State = State

type AddressValue = Omit<Data.Address, 'firstName' | 'lastName' | 'name' | 'addressNotes'>

type AddressFormProps = {
  parentKey?: string
  required?: boolean
} & PropsWithChildren<FormProps<AddressValue>>

const AddressForm: FC<AddressFormProps> = ({ children, parentKey, required = true }) => {
  const itemName = useCallback((name: keyof AddressValue) => (parentKey ? [parentKey, name] : name), [parentKey])

  return (
    <Country>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Item
            rules={[
              {
                required: required,
              },
            ]}
            label={'Country'}
            name={itemName('country')}
          >
            <Country.Select />
          </Item>
        </Col>
        <Col span={24} lg={16}>
          <Item
            rules={[
              {
                required: required,
              },
            ]}
            label={'Address'}
            name={itemName('address')}
          >
            <Input type={'text'} />
          </Item>
        </Col>
        <Col span={24} lg={8}>
          <Item label={'Apt # / Suite'} name={itemName('address2')}>
            <Input />
          </Item>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 24]}>
            <Col span={24} md={12}>
              <Item
                rules={[
                  {
                    required: required,
                  },
                ]}
                label={'City'}
                name={itemName('city')}
              >
                <Input type={'text'} />
              </Item>
            </Col>
            <Col span={12} lg={6}>
              <Country.State name={itemName('state')} required={required} />
            </Col>
            <Col span={12} lg={6}>
              <Country.ZipCode name={itemName('zipCode')} required={required} />
            </Col>
          </Row>
        </Col>
        {children && <Col span={24}>{children}</Col>}
      </Row>
    </Country>
  )
}

export { AddressForm, Country }
