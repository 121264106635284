import { FC, useCallback, useMemo } from 'react'

import { Flex, Form, Radio, RadioProps } from 'antd'

import { useColor } from 'src/components/app'
import { Card } from 'src/components/card'
import IvyIcon from 'src/components/icon'
import { Avatar } from 'src/components/image/Avatar'

import Text from '../../text/Text'

export type RadioCardProps = {
  title: string
  description?: string
  icon?: Design.IvyIcon
} & RadioProps

const RadioCard: FC<RadioCardProps> = ({ title, description, icon, name, disabled, ...props }) => {
  const { token, components } = useColor()
  const form = Form.useFormInstance()
  const currentValue = Form.useWatch(name, form)
  const handleClick = useCallback(() => {
    if (disabled) return
    name && form.setFieldsValue({ [name]: props.value })
  }, [form, disabled])

  const checked = useMemo(() => {
    return currentValue === props.value
  }, [currentValue])

  return (
    <Card
      autoHeight
      onClick={handleClick}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: checked ? components.Radio.buttonCheckedBg : token.colorBgLayout,
        opacity: disabled ? 0.5 : 1,
      }}
      styles={{
        body: {
          padding: '8px 16px',
        },
      }}
      title={
        <Flex justify={'space-between'}>
          <Flex align={'center'} gap={8}>
            {icon && (
              <Avatar
                color={'success'}
                shape={'square'}
                size={40}
                icon={<IvyIcon color={'success'} size={20} type={icon} />}
                background={checked ? 'success' : 'ghost'}
              />
            )}
            <Text
              style={{
                fontSize: token.fontSize,
                fontWeight: 600,
              }}
            >
              {title}
            </Text>
          </Flex>

          <Radio type={'radio'} disabled={disabled} {...props} />
        </Flex>
      }
      description={
        <Flex vertical>
          {description && (
            <Text
              style={{
                fontSize: token.fontSizeSM,
              }}
            >
              {description}
            </Text>
          )}
        </Flex>
      }
    />
  )
}

export default RadioCard
