import { lazy } from 'react'
import { Navigate } from 'react-router'

import Account from './Account'

const Contact = lazy(() => import('./tabs/Contact'))
const Billing = lazy(() => import('./tabs/billing/Billing'))
const Memberships = lazy(() => import('./tabs/membership/Memberships'))
const Profile = lazy(() => import('./tabs/profile'))
const CreditCardEdit = lazy(() => import('./tabs/wallet/credit-card/CreditCardEdit'))
const Wallet = lazy(() => import('./tabs/wallet/Wallet'))

const AccountRoutes: SDK.Components.Route = {
  path: 'account/*',
  Component: Account,
  membersOnly: false,
  children: [
    {
      path: 'memberships/:tab?',
      Component: Memberships,
    },
    {
      path: 'profile/:tab?',
      Component: Profile,
    },
    {
      path: 'wallet/:tab?',
      Component: Wallet,
      children: [
        {
          path: 'payment-methods',
          children: [
            {
              path: 'credit-cards/:id',
              Component: CreditCardEdit,
            },
          ],
        },
      ],
    },
    {
      path: 'billing/:tab?',
      Component: Billing,
    },
    {
      path: 'contact',
      Component: Contact,
    },
    {
      path: '*',
      element: <Navigate to={'/account/memberships'} />,
    },
  ],
}

export default AccountRoutes
