import { Entity } from '@data-client/rest'

export type MediaType = 'image' | 'video' | 'embed' | 'url' | 'file'

export class ImageEntity extends Entity {
  readonly title: string = ''
  readonly description: string = ''
  readonly url: string = ''
  readonly main: boolean = true
  readonly type: API.MediaType = 'image'

  pk(): string {
    return this.url
  }
}
