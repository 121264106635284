export const camelToSnakeCase = (obj: Object) =>
  Object.entries(obj).reduce((acc, [key, val]) => {
    const modifiedKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
    return {
      ...acc,
      ...{ [modifiedKey]: val },
    }
  }, {})

export const hasValidValue = (obj: Object) =>
  Object.values(obj).some((v) => {
    let valid = v !== undefined && v !== null

    if (valid) {
      if (v.constructor.name === 'Set') {
        valid = v.size > 0
      } else {
        if (Array.isArray(v)) {
          valid = v.length > 0
        }
      }
    }

    return valid
  })

// Utility function to compare relevant fields of two objects
export const isEqualPartial = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !isEqualPartial(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}