import { schema } from '@data-client/rest'
import { AccountWallet } from '../../datasource/account/wallet'
import { BillingAddress } from '../../datasource/address'
import { ApiEndpoint, createApiResource } from '../../datasource/api/endpoint'
import { BankAccountWallet, CreditCardWallet } from '../../datasource/wallet/wallet'

type AccountWalletPaymentIntent = {
  readonly customerId: Data.ID
  readonly clientSecret: string
  readonly setupIntentId: string
}

type WalletRequestBody = {
  token?: string
  accountId?: string
  tokenType?: string
}

type BankAccountCreateRequest = WalletRequestBody & {
  country?: string
  currency?: string
  routingNumber?: string
  accountNumber?: string
  nickName?: string
  accountHolderName?: string
  default?: boolean
  billingDetails?: Partial<Omit<Data.Address, 'firstName' | 'lastName'>>
}

type BankAccountVerifyRequest = {
  amount_1: number
  amount_2: number
}

type BankAccountUpdateRequest = {
  default: boolean
  nickname: string
  billingUse: string
}

type CreditCardCreate = {
  token: string
  nickname: string
  billingDetails: BillingAddress
  billingUse: API.WalletBillingUse
  paymentProcessorId?: Data.ID
}

type CreditCardUpdate = {
  default: boolean
  nickname: string
  expMonth: string
  expYear: string
  billingUse: API.WalletBillingUse
  billingDetails: Pick<BillingAddress, 'country' | 'zipCode' | 'name'>
}

const CreditCardBase = createApiResource({
  path: '/api/account/wallet/cards/:id',
  schema: CreditCardWallet,
}).extend('partialUpdate', {
  body: {} as CreditCardUpdate,
  schema: CreditCardWallet
}).extend('create', {
  body: {} as CreditCardCreate,
  schema: CreditCardWallet,
})

const setupIntent = new ApiEndpoint({
  method: 'GET',
  path: '/api/account/wallet/stripe/:id/intent',
  schema: {} as AccountWalletPaymentIntent,
})

const CreditCardResource = {
  ...CreditCardBase,
  setupIntent
}

const BankAccountBase = createApiResource({
  path: `/api/account/wallet/banks/:id`,
  schema: BankAccountWallet,
})

const BankAccountResource = {
  ...BankAccountBase,
  create: BankAccountBase.getList.push.extend({ body: {} as BankAccountCreateRequest & { accountId?: string } }),
  partialUpdate: BankAccountBase.partialUpdate.extend({
    path: '/api/account/wallet/banks/:id',
    body: {} as BankAccountUpdateRequest,
  }),
  verify: new ApiEndpoint({
    path: `/api/account/wallet/banks/:id/verify`,
    method: 'POST',
    body: {} as BankAccountVerifyRequest,
    schema: BankAccountWallet,
  }),
}

const WalletBaseResource = createApiResource({
  path: '/api/account/wallet/:id',
  schema: AccountWallet,
})
  .extend('getList', {
    schema: {
      cards: new schema.Collection([CreditCardWallet]),
      banks: new schema.Collection([BankAccountWallet]),
    },
  })


const WalletResource = {
  ...WalletBaseResource,
  cards: CreditCardResource,
  banks: BankAccountResource
}

export { BankAccountResource, CreditCardResource, WalletResource }

