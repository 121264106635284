import { CSSProperties, FC, Fragment, PropsWithChildren, ReactElement, ReactNode } from 'react'

import { Badge, Layout as BaseLayout, Col, Flex, LayoutProps, Row, Space } from 'antd'
import { SpaceProps as Props } from 'antd/es/space'

import { withPrefix } from '../../providers/ConfigProvider'
import Text from '..//text/Text'
import { useColor } from '../app'
import { useResponsive } from '../screen'
import { Title } from '../text/Title'

type SectionProps = {
  title?: ReactNode
  subTitle?: ReactNode
  titleSize?: 1 | 2 | 3 | 5 | 4
  titleType?: SDK.Components.ColorVariant
  size?: number
  className?: string
  style?: CSSProperties
  loading?: boolean
  extra?: ReactNode
  collapseOnMobile?: boolean
  badgeCount?: number
  onHandleTextClick?: () => void
  weight?: number
} & PropsWithChildren

type SpaceProps = Props & {
  bordered?: boolean
  justify?: '' | 'left' | 'center' | 'right' | 'space-between'
}

const VerticalSpace: FC<SpaceProps> = ({ bordered = false, justify = '', style, children, ...baseProps }) => {
  const { token } = useColor()
  return (
    <Space
      direction={'vertical'}
      style={{
        borderRadius: token.borderRadius,
        border: bordered ? `1px solid ${token.colorBorder}` : 'none',
        width: '100%',
        justifyContent: justify,
        ...style,
      }}
      {...baseProps}
    >
      {children}
    </Space>
  )
}

const HorizontalSpace: FC<SpaceProps> = ({ justify = '', style, children, ...baseProps }) => {
  return (
    <Space
      direction={'horizontal'}
      wrap={false}
      style={{ width: '100%', justifyContent: justify, ...style }}
      {...baseProps}
    >
      {children}
    </Space>
  )
}

const Split: FC<Props> = ({ style, children, direction = 'horizontal', ...baseProps }) => (
  <Space
    direction={direction}
    wrap={false}
    style={{ width: '100%', justifyContent: 'space-between', ...style }}
    {...baseProps}
  >
    {children}
  </Space>
)

const Section: FC<SectionProps> = ({
  title,
  subTitle,
  size = 8,
  titleSize = 3,
  weight = 700,
  loading = false,
  style = {},
  badgeCount,
  extra,
  collapseOnMobile,
  children,
}) => {
  const { textByVariant } = useColor()

  return (
    <Row
      gutter={[0, size]}
      style={{
        ...style,
      }}
      className={withPrefix('section')}
      justify={'space-between'}
      align={'middle'}
    >
      <Col span={24}>
        <Row wrap={collapseOnMobile} align={'middle'} justify={'space-between'} gutter={[0, 16]}>
          <Col>
            <Row align={'middle'} justify={'space-between'}>
              <Col span={24}>
                <Flex align={'center'} gap={size}>
                  {typeof title === 'string' ? (
                    <Title weight={weight} translate={'yes'} loading={loading} level={titleSize}>
                      {title}
                    </Title>
                  ) : (
                    title
                  )}
                  {typeof badgeCount === 'number' && badgeCount > 0 && (
                    <Badge
                      color={'var(--pv-color-primary)'}
                      count={badgeCount}
                      size={'default'}
                      overflowCount={99999}
                      styles={{ indicator: { color: textByVariant('light') } }}
                    />
                  )}
                </Flex>
              </Col>
              {subTitle && (
                <Col span={24}>
                  {typeof subTitle === 'string' ? <Text translate={'no'}>{subTitle}</Text> : subTitle}
                </Col>
              )}
            </Row>
          </Col>
          {extra && (
            <Col
              xs={{
                span: 24,
                flex: collapseOnMobile ? '1 1 auto' : 'none',
              }}
              lg={{
                flex: 'none',
              }}
            >
              {extra}
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>{children}</Col>
    </Row>
  )
}

const Sections: FC<{ children: ReactElement<SectionProps>[] }> = ({ children }) => {
  return (
    <Flex vertical gap={32}>
      {children.map((Child, index) => (
        <Fragment key={index}>{Child}</Fragment>
      ))}
    </Flex>
  )
}

const Layout: FC<PropsWithChildren<LayoutProps>> = ({ className: defaultClassName = '', ...props }) => {
  const { components } = useColor()
  const { isMobile, isTablet } = useResponsive()
  const siderCurrentWidth = props.hasSider
    ? isMobile
      ? 0
      : isTablet
        ? components['Layout.Sider'].collapsedWidth
        : components['Layout.Sider'].width
    : 0

  return (
    <BaseLayout
      style={{
        height: '100%',
        minHeight: '100vh',
        marginLeft: siderCurrentWidth,
      }}
      {...props}
    />
  )
}

export { CenteredContent } from './CenteredContent'

export { HorizontalSpace, Layout, Section, Sections, Split, VerticalSpace, type SectionProps }
