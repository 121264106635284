import { decode, encode } from 'base-64'
import camelcaseKeys from 'camelcase-keys'
import ls from 'localstorage-slim'

import type { TokenEntity } from '../datasource/account/account'
import { publicAxios } from './axios'

type TokenUserType = 'customer' | 'developer'

const developerToken = 'pv.dev.token' as const
const developerRefresh = 'pv.dev.refresh' as const

const customerToken = 'pv.token' as const
const customerRefresh = 'pv.refresh' as const
const customerWS = 'pv.ws' as const

const tokenNames = {
  customer: { token: customerToken, refresh: customerRefresh, ws: customerWS },
  developer: { token: developerToken, refresh: developerRefresh, sso: '', ws: '' },
} as const

const setToken = (token: Partial<TokenEntity>, type: TokenUserType = 'customer') => {
  if (token.accessToken) {
    ls.set(tokenNames[type].token, encode(token.accessToken), { ttl: token.expiresIn })
  }
  if (token.wsToken) {
    ls.set(tokenNames[type].ws, encode(token.wsToken), { ttl: token.expiresIn })
  }
  if (token.refreshToken) {
    ls.set(tokenNames[type].refresh, encode(token.refreshToken), { ttl: token.refreshExpiresIn })
  }
}

const refreshToken = async (type: TokenUserType = 'customer'): Promise<string | null> => {
  const refresh = fetchRefresh(type)

  if (!refresh) {
    removeToken(type)
    return null
  }

  try {
    const response = await publicAxios.post('/api/token', {
      grant_type: 'refresh_token',
      refresh_token: refresh,
    })

    const token = camelcaseKeys(response.data, { deep: true }) as TokenEntity
    setToken(token, type)
    return token.accessToken
  } catch (error) {
    removeToken(type)
    return null
  }
}

const fetchToken = async (type: TokenUserType = 'customer'): Promise<string | null> => {
  const token = ls.get<string>(tokenNames[type].token)
  if (token) {
    return decode(token)
  }

  const refresh = ls.get<string>(tokenNames[type].refresh)
  if (refresh) {
    return await refreshToken(type)
  }

  return null
}

// const fetchSSO = async (): Promise<string | null> => {
//   const refresh = ls.get<string>(tokenNames.customer.refresh)
//   if (refresh) {
//     await refreshToken('customer')
//   }
//   return ls.get<string>(tokenNames.customer.sso)
// }

const fetchRefresh = (type: TokenUserType = 'customer') => {
  const refresh = ls.get<string>(tokenNames[type].refresh)
  return refresh ? decode(refresh) : null
}

const fetchFull = async (type: TokenUserType = 'customer') => {
  const token = await fetchToken(type)
  if (token === null) return null
  const refresh = ls.get<string>(tokenNames[type].refresh)

  return {
    token: token,
    refresh: refresh ? decode(refresh) : null,
  }
}

const removeToken = (type: TokenUserType = 'customer') => {
  ls.remove(tokenNames[type].token)
  ls.remove(tokenNames[type].refresh)
  // ls.remove(tokenNames[type].sso)
}

export { fetchFull, fetchRefresh, fetchToken, refreshToken, removeToken, setToken }
