import { ComponentType, FC } from 'react'
import { type RouteObject } from 'react-router-dom'

import { Result } from '@peoplevine/sdk/components/result'
import { useAuth } from '@peoplevine/sdk/providers'
import { useConfig, useMenuConfig } from '@peoplevine/sdk/providers/ConfigProvider'
import routes from '@peoplevine/sdk/routes'

import { useAccount } from 'src/components/account/Provider'
import Login from 'src/components/user/Login'
import Unauthorized from 'src/layouts/Unauthorized'

const MemberSecurityGate: FC<{ secured?: boolean; path?: string; Component: ComponentType }> = ({
  secured,
  Component,
}) => {
  const { customer } = useAccount()
  return !customer.isMember && secured ? <Result.MembersOnly fullscreen={false} /> : <Component />
}

const MenuPageSecurityGate: FC<{ path?: string; Component: ComponentType }> = ({
  Component = Result.NotFound,
  path = '',
}) => {
  const { pageByUri } = useMenuConfig()
  const page = pageByUri(path)
  if (page && !page.enabled) return <Result.NotFound />
  return <Component />
}

const GuestSecurityGate: FC<{ secured?: boolean; Component: ComponentType }> = ({ Component, secured = false }) => {
  const { authenticated } = useAuth()
  if (!secured || authenticated === true) return <Component />
  return authenticated === false ? (
    <Unauthorized>
      <Login />
    </Unauthorized>
  ) : (
    <Component />
  )
}

const PlusPlanSecurityGate: FC<{ secured?: boolean; Component: ComponentType | null | undefined }> = ({
  Component = null,
  secured = false,
}) => {
  const { plan } = useConfig()
  return plan !== 'Plus' && secured ? <Result.NotFound /> : Component && <Component />
}

const pathAliases = <T extends SDK.Components.Route>({ aliases = [], id, ...route }: T): SDK.Components.Route[] => {
  return [
    {
      id,
      ...route,
    },
    ...aliases?.map((alias, index) => ({
      ...route,
      id: [id ?? route.path, index].join('@'),
      path: alias,
    })),
  ]
}

const menuPagePolicy = <T extends SDK.Components.Route>({
  Component = Result.NotFound,
  ...route
}: T): SDK.Components.Route => {
  return {
    ...route,
    Component: () => <MenuPageSecurityGate path={route.path} Component={Component} />,
  }
}

const routeEntries = (
  object: Partial<{
    [key in keyof typeof routes]: SDK.Components.Route
  }>,
): SDK.Components.Route[] => {
  return Object.entries(object).map(([id, { wildcard, children, element, ...routeProps }]) => {
    let path = routes[id].path ?? routes[id]
    return {
      path: wildcard ? `${path}/*` : path,
      children,
      ...routeProps,
    }
  })
}

const guestPolicy = <T extends SDK.Components.Route>({ Component, secure, ...route }: T): SDK.Components.Route => {
  return {
    ...route,
    Component: () => <GuestSecurityGate secured={secure} Component={Component} />,
    // children: children?.map(guestPolicy),
    ...route,
  }
}

const membersPolicy = ({ Component, ...route }: SDK.Components.Route): SDK.Components.Route => ({
  ...route,
  Component: () => <MemberSecurityGate path={route.path} secured={route.membersOnly} Component={Component} />,
})

const plusPlanPolicy = (route: SDK.Components.Route): SDK.Components.Route => ({
  ...route,
  Component: () => <PlusPlanSecurityGate secured={route.plusPlan} Component={route.Component} />,
})

const errorBoundary = ({ children = [], ...route }: SDK.Components.Route): RouteObject => ({
  ...route,
  children,
})

const routeObject = ({ membersOnly, Suspense, plusPlan, aliases, ...route }: SDK.Components.Route): RouteObject => {
  return route
}

export {
  errorBoundary,
  guestPolicy,
  membersPolicy,
  menuPagePolicy,
  pathAliases,
  plusPlanPolicy,
  routeEntries,
  routeObject,
}
