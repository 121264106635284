import { createApiResource } from './api/endpoint'
import { ApiEntity } from './api/entity'
import { BankAccountWallet, CreditCardWallet } from './wallet/wallet'

export type SubscriptionStatus = '' | 'active' | 'cancelled' | 'expired' | 'failed' | 'pending'
export type SubscriptionFrequency = '' | 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'semi' | 'annually'
type SubscriptionType = 'donation' | 'fundraiser' | 'membership' | 'product' | 'service'

type SubscriptionLines = {
  readonly service: number
  readonly tax: number
  readonly amount: number
}

class SubscriptionEntity extends ApiEntity implements Data.Transactional {
  static readonly urlRoot = `/api/account/subscriptions`
  readonly autoCharge: boolean = true
  readonly createdOn: Date = new Date(0)
  readonly customerAddressId: number = 0
  readonly customerId: number = 0
  readonly details: string = ''
  readonly frequency: SubscriptionFrequency = ''
  readonly id: number = 0
  readonly lastDate: Date = new Date()
  readonly lastAmount?: number
  readonly nextDate: Date = new Date()
  readonly quantity: number = 0
  readonly rate: number = 0
  readonly taxRate: number = 0
  readonly serviceRate: number = 0
  readonly referenceId: number = 0
  readonly type?: SubscriptionType
  readonly currency?: string
  readonly remainingPayments: number = 0
  readonly renewalDate: Date = new Date(0)
  readonly renewable: boolean = true
  readonly requestedCancelDate: Date = new Date(0)
  readonly serviceId: number = 0
  readonly shippingCourier: string = ''
  readonly shippingMethod: string = ''
  readonly status: SubscriptionStatus = ''
  readonly totalPayments: number = 0
  readonly paymentCount: number = 0
  readonly invoiceEnabled: boolean = false
  readonly wallet?: BankAccountWallet | CreditCardWallet
  readonly lines: SubscriptionLines = {
    amount: 0,
    tax: 0,
    service: 0,
  }

  get paymentLines(): { [key in Exclude<API.WalletPaymentType, 'houseAccount'>]: SubscriptionLines } {
    const rate = this.rate * this.paymentCount
    const serviceFee = rate * this.serviceRate
    const taxAmount = (rate + serviceFee) * this.taxRate
    return {
      creditcard: {
        service: Math.round((serviceFee + Number.EPSILON) * 100) / 100,
        tax: Math.round((taxAmount + Number.EPSILON) * 100) / 100,
        amount: Math.round((rate + serviceFee + taxAmount + Number.EPSILON) * 100) / 100,
      },
      ach: {
        service: 0,
        tax: this.taxRate * rate,
        amount: this.rate + this.taxRate * rate,
      },
    }
  }

  get paymentMethod(): BankAccountWallet | CreditCardWallet | undefined {
    if (this.wallet) {
      return this.wallet.paymentType === 'creditcard'
        ? CreditCardWallet.fromJS(this.wallet as CreditCardWallet)
        : BankAccountWallet.fromJS(this.wallet as BankAccountWallet)
    }
    return this.wallet
  }

  get madePayment(): boolean {
    return this.lastDate.toString() !== '1900-01-01T00:00:00' && this.lastAmount !== undefined && this.lastAmount > 0
  }

  get pastDue(): boolean {
    return this.nextDate < new Date()
  }

  get period(): string {
    switch (this.frequency) {
      case 'daily':
        return 'day'
      case 'weekly':
        return 'week'
      case 'monthly':
        return 'month'
      case 'quarterly':
        return 'quarter'
      case 'semi':
        return '6 months'
      case 'annually':
        return 'year'
      default:
        return ''
    }
  }

  get periodPlural(): string {
    switch (this.frequency) {
      case 'daily':
        return 'days'
      case 'weekly':
        return 'weeks'
      case 'monthly':
        return 'months'
      case 'quarterly':
        return 'quarters'
      case 'semi':
        return '6 month periods'
      case 'annually':
        return 'years'
      default:
        return ''
    }
  }
}

const SubscriptionBase = createApiResource({
  path: '/api/account/subscriptions/:id',
  schema: SubscriptionEntity,
}).extend('partialUpdate', {
  method: 'PATCH',
  body: {} as { walletId: Data.ID },
})

const SubscriptionResource = {
  ...SubscriptionBase,
}

export { SubscriptionEntity, SubscriptionResource }
