import { DateRangeEntity, DateTz } from '../api'
import { dayjs } from '../services/dayjs'
import { ApiEntity } from './api/entity'
import { BusinessLocation } from './business'
import { Category } from './category'
import { DayOfWeek } from './intl'

export type TimeSlotParams = {
  startDate?: string | Date
  endDate?: string | Date
  partySize?: number
  scheduleId?: Data.ID
}

export type TimeSlotCharges = {
  reservation: number
  service?: number
}

class TimeSlot extends ApiEntity implements Data.TemporalLocal {
  static schema = {
    startDate: (iso: string) => dayjs(iso).toDate(),
    endDate: (iso: string) => dayjs(iso).toDate(),
    startDateLocal: DateTz,
    endDateLocal: DateTz,
  }
  readonly title: string = ''
  readonly description: string = ''
  readonly scheduleId: number = 0
  readonly appointmentId: number = 0
  readonly fee: number = 0
  readonly feeType?: 'Reservation' | 'Person'
  readonly charges?: TimeSlotCharges
  readonly reservationId: number = 0
  readonly slotId: string = ''
  readonly subject: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS()
  readonly endDateLocal: DateTz = DateTz.fromJS()
  readonly capacity: number = 0
  readonly quantity: number = 0
  readonly duration: number = 0
  readonly location?: BusinessLocation
  readonly id: string = ''
  readonly metadata?: any

  get range(): DateRangeEntity {
    return new DateRangeEntity(this.startDateLocal, this.endDateLocal)
  }
}

class SchedulerRules extends ApiEntity implements Data.Identified {
  readonly id: Data.ID = 0
  readonly day?: DayOfWeek
  readonly start: string = ''
  readonly end: string = ''
}

export type ScheduleBlockType = 'one' | 'multi' | 'daily' | 'dining' | 'spa' | ''

type SchedulerSettings = {
  defaultStartTime?: string
  minimumCapacity?: number
  enableWaitlist?: boolean
}

class Scheduler extends ApiEntity implements Data.Identified, Data.Named, Data.Imaginated, Data.SingleCategory {
  readonly name: string = ''
  readonly description: string = ''
  readonly allowCancel: boolean = false
  readonly location: string = ''
  readonly blockDuration: number = 0
  readonly blockType: ScheduleBlockType = ''
  readonly businessId: number = 0
  readonly businessLocationId: number = 0
  readonly cancelBy: number = 0
  readonly categoryBlock: boolean = false
  readonly categoryId: number = 0
  readonly maxBlocks: number = 0
  readonly maxDaysOut: number = 0
  readonly capacity: number = 0
  readonly type: API.ScheduleType = 'appointment'
  readonly surveyId: number = 0
  readonly startingPrice: number = 0
  readonly category: Category = Category.fromJS()
  readonly rules: SchedulerRules[] = []
  readonly settings?: SchedulerSettings
  readonly graphic: string

  get image(): string {
    return this.graphic ?? ''
  }

  get images(): string[] {
    return [this.image]
  }

  get categories(): Category[] {
    return [this.category]
  }
}

export { Scheduler, TimeSlot }
