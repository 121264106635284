import { FC, PropsWithChildren } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { useConfig } from './ConfigProvider'
import { useI18n } from './I18n'

const MetaProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    features,
    props: { favicon },
    theme,
    company,
  } = useConfig()
  const { locale } = useI18n()
  const {
    locale: { language },
  } = useI18n()
  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: locale.language }}>
        <title>{`${company.name} Member Portal`}</title>
        <link href={favicon ?? '/favicon.svg'} rel={'icon'} type={'image/svg'} />
        {features?.appId && (
          <meta
            name={'apple-itunes-app'}
            content={`app-id=${features.appId}, app-argument=pvapp:///redirect?to=${window.location.pathname}`}
          />
        )}
      </Helmet>
      {children}
    </HelmetProvider>
  )
}

export { MetaProvider }
