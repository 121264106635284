import { FC, ReactNode, useMemo } from 'react'

import { Checkbox, CheckboxProps, Col, Form, Row } from 'antd'

import { CheckableCard } from 'src/components/card/CheckableCard'

import { Item } from '..'

interface CheckBoxGroupItem {
  title: ReactNode
  avatar?: SDK.Components.AvatarProps
  description?: ReactNode
  value: string | number
  disabled?: boolean
}

interface CheckBoxGroupProps {
  name: string
  initialValues?: (string | number)[]
  items: CheckBoxGroupItem[]
  checkAll?: string
}

const CheckboxCardGroup: FC<CheckBoxGroupProps> = ({ name, items, checkAll, initialValues = [] }) => {
  const form = Form.useFormInstance()
  const values = Form.useWatch<(string | number)[]>(name, form) ?? initialValues
  const options = items.filter((it) => !it.disabled).map((it) => it.value)

  const disabledItems = useMemo(() => items.filter((it) => it.disabled).map((it) => it.value), [items])
  const enableCheckAll = checkAll && options.length > 0

  const allChecked = useMemo(() => options.every((value) => values.includes(value)), [values, options])

  const updateFormItem = (items: (string | number)[]) => {
    form.setFieldsValue({
      [name]: items,
    })
  }

  const handleCheckAll: CheckboxProps['onChange'] = (e) => {
    updateFormItem(e.target.checked ? Array.from(new Set([...options, ...values])) : [...disabledItems])
  }

  const handleChange = (checked: boolean, value: string | number) => {
    updateFormItem(checked ? [...values, value] : values.filter((item: string | number) => item !== value))
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Item name={name}>
          <Row gutter={[16, 16]}>
            {items.map((item) => (
              <Col span={24} key={item.value}>
                <CheckableCard
                  title={item.title}
                  description={item.description}
                  avatar={item.avatar}
                  key={item.value}
                  extra={'checkbox'}
                  checked={values.includes(item.value)}
                  enableUncheck
                  disabled={item.disabled}
                  onChange={(checked) => handleChange(checked, item.value)}
                />
              </Col>
            ))}
          </Row>
        </Item>
      </Col>
      {enableCheckAll && (
        <Col span={24}>
          <Checkbox checked={allChecked} onChange={handleCheckAll}>
            {checkAll}
          </Checkbox>
        </Col>
      )}
    </Row>
  )
}

export default CheckboxCardGroup
