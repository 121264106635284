import { CSSProperties, FC, useCallback, useEffect, useMemo } from 'react'
import { useSet } from 'react-use'

import { List, Tag as Wrapped } from 'antd'

import { Text } from 'src/components/text'

import { useColor } from '../app'
import IvyIcon from '../icon'

function isDefaultColorVariant(value: string): value is SDK.Components.StatusColorVariant {
  return ['success', 'warning', 'error', 'processing', 'default'].includes(value)
}

const Tag: FC<SDK.Components.TagProps> = ({
  shape = 'asymmetric',
  type = 'default',
  size = 'small',
  icon,
  checkable = false,
  checked,
  defaultChecked = false,
  disabled,
  onCheck,
  bordered = true,
  children = '',
  style,
  ...baseProps
}) => {
  const { bgByVariant, textByBgVariant, token } = useColor()

  const tagTextByVariant = useCallback(
    (tagType: SDK.Components.ColorVariant | undefined) => {
      switch (tagType) {
        case 'success':
          return token?.colorSuccessText
        case 'warning':
          return token?.colorWarningText
        case 'error':
          return token?.colorError
        case 'processing':
          return token?.colorInfoText
        case 'default':
          return token?.colorTextSecondary
        default:
          return token?.colorTextBase
      }
    },
    [token],
  )

  const byByVariant = useCallback(
    (tagType: SDK.Components.ColorVariant) => (isDefaultColorVariant(tagType) ? tagType : bgByVariant(tagType, true)),
    [bgByVariant],
  )

  const tagTextColorByVariant = useCallback(
    (tagType: SDK.Components.ColorVariant) =>
      isDefaultColorVariant(tagType) ? tagTextByVariant(tagType) : textByBgVariant(tagType),
    [tagTextByVariant, textByBgVariant],
  )

  const tagBgColorByVariant = useCallback(
    (tagType: SDK.Components.ColorVariant) => bgByVariant(tagType, true),
    [bgByVariant],
  )

  const tagStyle = useMemo(() => {
    let defaults: CSSProperties = {
      border: bordered ? `1px solid ${tagTextColorByVariant(type)}` : undefined,
      borderRadius: 24,
      padding: '4px 8px',
      marginBottom: 0,
      maxWidth: 'fit-content',
      letterSpacing: 0.5,
      ...style,
    }

    if (checkable) {
      defaults = {
        ...defaults,
        cursor: 'pointer',
        borderColor: checked ? tagTextColorByVariant('primary') : token.colorBorder,
      }
    }

    return defaults
  }, [bordered, type, style, checkable, checked, tagTextColorByVariant, token.colorBorder])

  return checkable ? (
    <Wrapped.CheckableTag
      checked={checked ?? defaultChecked}
      onChange={(checked) => {
        if (!checkable) return
        onCheck && onCheck(checked)
      }}
      color={checkable && checked ? byByVariant('primary') : 'default'}
      style={tagStyle}
      {...baseProps}
    >
      <Text
        size={size}
        font={{ family: 'secondary', size: 11, weight: 700 }}
        style={{ color: 'inherit', textTransform: 'uppercase' }}
        uppercased
      >
        {children}
      </Text>
    </Wrapped.CheckableTag>
  ) : (
    <Wrapped
      defaultChecked={defaultChecked}
      bordered={bordered}
      icon={icon && <IvyIcon type={icon} />}
      color={byByVariant(type)}
      style={{
        backgroundColor: type === 'default' ? token.colorWhite : tagBgColorByVariant(type),
        ...tagStyle,
      }}
      {...baseProps}
    >
      <Text
        size={size}
        font={{ family: 'secondary', size: 10, weight: 700 }}
        color={tagTextColorByVariant(type)}
        uppercased
      >
        {children}
      </Text>
    </Wrapped>
  )
}

const Tags: FC<SDK.Components.TagsProps> = ({
  shape = 'asymmetric',
  type = 'default',
  size = 'small',
  checked: defaultChecked,
  checkable,
  onChange = () => {},
  onCheck = () => {},
  multi = true,
  data = [],
}) => {
  const [allChecked, { has, add, toggle, clear }] = useSet<string>(
    new Set(defaultChecked ? (Array.isArray(defaultChecked) ? defaultChecked.map(String) : [`${defaultChecked}`]) : []),
  )

  const handleCheck = useCallback(
    (id: Data.ID) => {
      if (multi) {
        toggle(`${id}`)
      } else {
        const checked = has(`${id}`)
        clear()
        if (!checked) {
          add(`${id}`)
        }
        onCheck(id, !checked)
      }
    },
    [multi, has, toggle, add, clear, onCheck],
  )

  useEffect(() => {
    onChange(allChecked)
  }, [allChecked, onChange])

  return (
    <List
      rowKey={'id'}
      split={false}
      grid={{ gutter: [8, 8] }}
      bordered={false}
      dataSource={data}
      renderItem={(tag) => {
        return (
          <List.Item style={{ marginBottom: 0 }}>
            <Tag
              type={type}
              key={tag.id}
              size={size}
              shape={shape}
              checked={has(`${tag.id}`)}
              checkable={checkable}
              onCheck={() => handleCheck(tag.id)}
            >
              {tag.title}
            </Tag>
          </List.Item>
        )
      }}
    />
  )
}

export { Tag, Tags }
