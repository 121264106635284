import { IntlConfig, PageConfig, PortalConfig } from '../../datasource/config'
import intl from './intl.json'
import pages from './pages.json'
import theme from './theme.json'

const config: Partial<PortalConfig> = {
  pages: pages.map((it) => PageConfig.fromJS(it)),
  theme,
  intl: IntlConfig.fromJS(intl),
} as const

export default PortalConfig.fromJS(config)
