import { ApiEndpoint } from './api/endpoint'
import { ApiEntity } from './api/entity'

export type DayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'

class Country extends ApiEntity implements Data.Named {
  static readonly urlRoot = `/api/static/countries`
  readonly name: string = ''
  readonly abbreviation: string = ''
  readonly phoneCode: string = ''
  readonly postalValidator?: string

  get pattern(): RegExp | undefined {
    if (!this.postalValidator) return
    return new RegExp(this.postalValidator, 'gi')
  }

  pk(): string {
    return this.abbreviation.toUpperCase()
  }
}

class State extends ApiEntity implements Data.Named {
  static readonly urlRoot = `/api/static/countries/us/states`
  readonly name: string = ''
  readonly abbreviation: string = ''

  pk(): string {
    return this.abbreviation
  }
}

const countries = new ApiEndpoint({
  path: '/api/static/countries',
  method: 'GET',
  schema: [Country],
})

const states = new ApiEndpoint({
  path: '/api/static/countries/us/states',
  method: 'GET',
  schema: [State],
})

const IntlResource = {
  countries,
  states,
}

export { Country, State, IntlResource }
