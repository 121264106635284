import { FC } from 'react'

import Text from '..//text/Text'

const SmallText: FC<SDK.Components.TextProps> = ({ align, block, children, ...props }) => (
  <Text font={{ size: 12 }} {...props}>
    {children}
  </Text>
)

export { SmallText }
export default SmallText
