import { useConfig } from '../../providers/ConfigProvider'
import { Logo } from '../logo/Logo'
import { useResponsive } from '../screen'

function useLoginLogo() {
  const { layout } = useConfig().props
  const { isMobile } = useResponsive()

  const logoVersion = !isMobile ? layout.loginLogo?.desktop : layout.loginLogo?.mobile

  if (!logoVersion) return undefined

  return {
    src: logoVersion.src,
    width: logoVersion.width ?? 260,
    justify: logoVersion.justify ?? 'left',
  }
}

function useLogoType() {
  const { logo } = useConfig().props.navigation
  const { isMobile } = useResponsive()

  const logoVersion = !isMobile ? logo?.desktop : logo?.mobile
  return {
    logo: logoVersion,
    justify: logoVersion?.justify ?? 'left',
  }
}
export { Logo, useLogoType, useLoginLogo }
export default Logo
