import type { FC } from 'react'

import { DataProvider, getDefaultManagers } from '@data-client/react'

import { AppProvider } from '@peoplevine/sdk/components/app/Provider'
import { DeveloperProvider } from '@peoplevine/sdk/components/developer/DeveloperProvider'
import { ConfigProvider, FeatureProvider, NativeAppProvider } from '@peoplevine/sdk/providers'

import { DrawerDetailProvider } from 'src/providers/DrawerDetailProvider'

import RoutesProvider from '../containers/routing'

const RootProvider: FC = () => (
  <NativeAppProvider>
    <DataProvider managers={getDefaultManagers()} devButton={'bottom-right'}>
      <ConfigProvider>
        <FeatureProvider>
          <AppProvider>
            <DrawerDetailProvider>
              <RoutesProvider />
            </DrawerDetailProvider>
            <DeveloperProvider />
          </AppProvider>
        </FeatureProvider>
      </ConfigProvider>
    </DataProvider>
  </NativeAppProvider>
)

export default RootProvider
