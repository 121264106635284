import axios, { AxiosHeaders } from 'axios'
import { fetchToken, refreshToken, removeToken } from './tokens'

const defaultHeaders: Partial<AxiosHeaders> = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
}

const privateAxios = axios.create({
  headers: defaultHeaders,
  withCredentials: true,
})
const publicAxios = axios.create({
  headers: defaultHeaders,
  withCredentials: true,
})

const adminAxios = axios.create({
  headers: defaultHeaders,
  withCredentials: true,
})

let isRefreshing = false
let failedQueue: { resolve: (value?: unknown) => void, reject: (reason?: any) => void }[] = []

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

adminAxios.interceptors.request.use(async config => {
  const token = await fetchToken('developer')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

privateAxios.interceptors.request.use(async config => {
  const token = await fetchToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

privateAxios.interceptors.response.use(
  response => response,
  async err => {
    const error = err.response?.data
    const message = error.error
    if (message === 'invalid_refresh_token' || message === 'invalid_token') {
      removeToken()
      return Promise.reject(error)
    }

    if (message === 'expired_token') {
      if (!isRefreshing) {
        isRefreshing = true
        let newToken: string | null = null
        try {
          newToken = await refreshToken()
          processQueue(null, newToken)
        } catch (err) {
          processQueue(err, null)
          return Promise.reject(err)
        } finally {
          isRefreshing = false
        }
      }

      return new Promise((resolve, reject) => {
        failedQueue.push({
          resolve: (token) => {
            error.config.headers.Authorization = `Bearer ${token}`
            resolve(privateAxios.request(error.config))
          },
          reject: (err) => {
            reject(err)
          },
        })
      })
    }
    return Promise.reject(err)
  }
)

export { adminAxios, privateAxios, publicAxios }

