import { decode, encode } from 'base-64'

export default function useBase64() {
  const encodeTo = <T extends string | unknown>(data: T) => {
    return encode(typeof data !== 'string' ? JSON.stringify(data) : data)
  }

  const decodeFrom = (data: string | undefined): string => {
    if (!data) return ''
    try {
      return decode(data)
    } catch (ex) {
      return ''
    }
  }
  return {
    encode: encodeTo,
    decode: decodeFrom,
  }
}
