import { FC, Fragment, PropsWithChildren, ReactNode, useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

import { Result as BaseResult, Flex } from 'antd'

import { I18nProvider, useI18n } from '../../providers'
import routes from '../../routes'
import Text from '..//text/Text'
import { Button } from '../button/Button'
import { FullScreenDrawer } from '../drawer'
import { DrawerCloseButton } from '../drawer/DrawerCloseButton'
import { Image } from '../image/Image'
import { CenteredContent } from '../layout'
import { useResponsive } from '../screen'

const assets: Record<`./assets/${ResultImage}.svg`, string> = import.meta.glob('./assets/*.svg', {
  import: 'default',
  eager: true,
})

type ResultImage =
  | 'checkout-error'
  | 'checkout-success'
  | 'page-error'
  | 'page-not-found'
  | 'page-success'
  | 'recommend-error'
  | 'recommend-success'
  | 'no-data'
  | 'add-info'

const ResultActions: FC<{ back?: boolean; logout?: boolean }> = ({ back = true, logout = true }) => {
  // TODO: Browser history include in back button
  return (
    <Flex vertical gap={16} style={{ width: '100%' }} align={'center'} justify={'center'}>
      {back && (
        <Button style={{ maxWidth: 200 }} key={'back'} href={'/'}>
          {'Go Back'}
        </Button>
      )}
      {logout && (
        <Button style={{ maxWidth: 200 }} key={'logout'} type={'default'} href={'/logout'}>
          Logout
        </Button>
      )}
    </Flex>
  )
}

const ResultImage: FC<{ name: ResultImage } & SDK.Components.ImageProps> = ({ name, ...props }) => {
  const { isMobile } = useResponsive()
  return (
    <Image
      src={assets[`./assets/${name}.svg`]}
      height={isMobile ? 167 : 212}
      preview={false}
      style={{ backgroundColor: 'unset', objectFit: 'contain' }}
      {...props}
    />
  )
}

type ResultPageProps = PropsWithChildren<
  Partial<{
    title: string
    subTitle: ReactNode
    status: number
    fullscreen: boolean
    name: ResultImage
    children?: ReactNode
    back?: boolean
    logout?: boolean
    drawerProps?: {
      closeable?: boolean
      onClose?: () => void
    }
    actions?: Pick<SDK.Components.ButtonProps, 'type' | 'onClick' | 'children'>[]
  }>
>

const ResultPage: FC<Partial<ResultPageProps>> = ({
  fullscreen = false,
  title,
  subTitle,
  name = 'page-success',
  actions,
  back,
  logout,
  children,
  drawerProps = {
    closeable: true,
    onClose: () => {},
  },
}) => {
  const [open, setOpen] = useState(true)
  const { t } = useI18n()

  const Wrapped = fullscreen
    ? ({ children }) => (
        <FullScreenDrawer
          destroyOnClose
          closeIcon={<DrawerCloseButton drawerPlacement={'fullscreen'} />}
          styles={{ wrapper: { transform: 'none' } }}
          closable={drawerProps?.closeable}
          open={open}
          onClose={() => {
            setOpen(false)
            drawerProps?.onClose?.()
          }}
        >
          <CenteredContent>{children}</CenteredContent>
        </FullScreenDrawer>
      )
    : Fragment

  return (
    <I18nProvider>
      <Wrapped>
        <Flex vertical align={'center'} justify={'center'} wrap>
          <BaseResult
            title={title && t(title)}
            subTitle={subTitle && typeof subTitle === 'string' ? t(subTitle) : subTitle}
            icon={<ResultImage name={name} />}
          />
          <Flex style={{ width: '100%' }} gap={16} align={'center'} justify={'center'}>
            {children ? (
              <Flex style={{ width: '100%' }} gap={16} align={'center'} justify={'center'}>
                {children}
              </Flex>
            ) : (
              <div>
                <ResultActions logout={logout} back={back} />
                {actions && actions?.length > 0 ? (
                  <Flex gap={16} style={{ width: '100%' }} justify={'center'}>
                    {actions?.map(({ children, onClick, type }, index) => (
                      <Button style={{ maxWidth: 200 }} key={index} type={type} onClick={onClick}>
                        {children}
                      </Button>
                    ))}
                  </Flex>
                ) : null}
              </div>
            )}
          </Flex>
        </Flex>
      </Wrapped>
    </I18nProvider>
  )
}

const Redirecting: FC<ResultPageProps> = ({ ...props }) => (
  <ResultPage title={'Redirecting'} subTitle={'Please wait while we redirect you.'} name={'page-error'} {...props} />
)

const NoMemberships: FC<ResultPageProps> = ({
  title = 'Not a Member',
  subTitle = 'Looks like you are not a member yet',
  back,
  ...props
}) => {
  return <ResultPage title={title} subTitle={subTitle} name={'page-error'} back={back} logout={false} {...props} />
}

const NominationFailed: FC<ResultPageProps> = ({
  title = 'Ooops. Your nomination failed',
  subTitle = 'We are sorry, but something went wrong with your nomination! Please check your connection or try again later.',
  ...props
}) => <ResultPage title={title} subTitle={subTitle} name={'recommend-error'} {...props} />

const NominateCompleted: FC<ResultPageProps> = ({
  title = 'You have submitted your recommendation request successfully!',
  subTitle = 'Congratulations! Your nominee will receive the invitation email in a short time.',
  children,
  ...props
}) => (
  <ResultPage title={title} subTitle={subTitle} name={'recommend-success'} {...props}>
    {children}
  </ResultPage>
)

const AdditionalMemberFailed: FC<ResultPageProps> = ({
  title = 'Ooops. There was a problem adding your additional member.',
  subTitle = 'We are sorry, but something went wrong with this additional member request.  Please check your connection or try again later.',
  ...props
}) => <ResultPage title={title} subTitle={subTitle} name={'recommend-success'} {...props} />

const AdditionalMemberCompleted: FC<ResultPageProps> = ({
  title = 'Your new member has been successfully added!',
  subTitle = 'Congratulations! Your new sub member will receive an email with details shortly.',
  ...props
}) => <ResultPage title={title} subTitle={subTitle} name={'recommend-success'} {...props} />

const Success: FC<ResultPageProps> = ({
  title = 'Congratulations',
  subTitle = 'Well done. Thanks for being with us.',
  ...props
}) => <ResultPage title={title} subTitle={subTitle} name={'page-success'} {...props} />

type ErrorPageProps = {
  backButton?: boolean
  status?: number
  title?: string
  children?: ReactNode
} & ResultPageProps

const Error: FC<ErrorPageProps> = ({
  title = 'Sorry, an unexpected error occurred',
  subTitle = 'We are working on fixing problem.',
  status = 500,
  children,
  ...props
}) => {
  return (
    <ResultPage status={status} title={title} subTitle={subTitle} name={'page-error'} {...props}>
      {children}
      <ResultActions back={props.back} logout={props.logout} />
    </ResultPage>
  )
}

const NotFound: FC<ResultPageProps> = ({ ...props }) => (
  <ResultPage
    title={'Oops. Page not found'}
    subTitle={'Looks like we can’t find this page anymore.'}
    name={'page-not-found'}
    {...props}
  />
)

const MembersOnly: FC<ResultPageProps> = (props) => (
  <ResultPage
    title={'Members Only'}
    subTitle={'Hold up!  You must be an active member to access this page.'}
    name={'page-error'}
    {...props}
  >
    <ResultActions logout={props.logout} back={props.back} />
  </ResultPage>
)

const NoData: FC<ResultPageProps> = ({ title, subTitle, ...props }) => (
  <ResultPage title={title} subTitle={subTitle} name={'no-data'} {...props} />
)

const Forbidden: FC<ResultPageProps> = ({ children, ...props }) => (
  <ResultPage
    title={'Hold up! Access Restricted'}
    subTitle={'We are sorry, but you do not have access to this page.'}
    name={'page-error'}
    {...props}
  >
    <ResultActions logout={false} />
  </ResultPage>
)

export type PaymentResultProps = {
  type?: string
} & ResultPageProps

const PaymentFailed: FC<PaymentResultProps> = ({ children, ...props }) => (
  <ResultPage
    title={'Checkout failed'}
    subTitle={'Your transaction has failed.  See the details below and try again.'}
    name={'checkout-error'}
    {...props}
  >
    {children ? children : <ResultActions logout={false} />}
  </ResultPage>
)
const PaymentCompleted: FC<PaymentResultProps> = ({ type, children, ...props }) => (
  <ResultPage title={`Thank you for your ${type ? type : 'order'}!`} name={'checkout-success'} fullscreen {...props}>
    {children ? children : <ResultActions logout={false} />}
  </ResultPage>
)

type NotImplementedProps = {
  reference?: string
} & ResultPageProps

const NotImplemented: FC<NotImplementedProps> = ({ reference, ...props }) => (
  <ResultPage
    title={'Hold up! Something is not implemented properly'}
    subTitle={
      <Text
        context={{ referenceId: reference }}
      >{`If you believe you have reached this error by mistake, contact customer service and mention reference code: {referenceid}`}</Text>
    }
    name={'page-not-found'}
    {...props}
  >
    <ResultActions logout={false} />
  </ResultPage>
)

const NotSetup: FC<ResultPageProps> = ({ title, subTitle, ...props }) => (
  <ResultPage
    title={title ?? "Whoops, You're not setup to do this"}
    subTitle={subTitle ?? 'You might be missing a required setting to do this'}
    name={'page-error'}
    {...props}
  />
)

const ConfigurationError: FC<ResultPageProps> = ({ title, subTitle, ...props }) => (
  <ResultPage
    title={title ?? "Whoops, You're not setup to do this"}
    subTitle={subTitle ?? 'You might be missing a required setting to do this'}
    name={'page-error'}
    {...props}
  />
)

const Result = {
  Success,
  NotFound,
  Forbidden,
  Error,
  NotImplemented,
  PaymentCompleted,
  PaymentFailed,
  NominateCompleted,
  NominationFailed,
  AdditionalMemberCompleted,
  AdditionalMemberFailed,
  NoMemberships,
  NotSetup,
  MembersOnly,
  Redirecting,
  NoData,
  ConfigurationError,
  ResultImage,
}

export { Result, ResultPage }

export default Result
