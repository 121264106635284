import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useController } from '@data-client/react'

import useLocalStorage from 'src/hooks/useLocalStorage'

import { AdminEntity, AdminResource, AdminTokenEntity } from '../../datasource/admin'
import { useNotification } from '../../providers/Notification'
import { fetchFull, fetchToken, setToken } from '../../services/tokens'

type AdminAuthType = 'user' | 'user_company'

type DeveloperProps = {
  userId: number
  companyId: number
  tokenType: AdminAuthType
}

type ContextProps = {
  adminUser: AdminEntity | undefined
  authType: AdminAuthType | undefined
  authenticated: boolean
  companyId?: Data.ID
  authenticate: (username: string, password: string) => Promise<void>
  switchCompany: (id: Data.ID) => Promise<void>
  onLogout: () => Promise<void> | void
}

const defaultValues = {
  authType: undefined,
  adminUser: undefined,
  authenticated: false,
  loading: false,
  companyId: 0,
  onLogout: () => Promise.resolve(),
  authenticate: async () => {},
  switchCompany: async () => {},
}

const DeveloperAuth = createContext<ContextProps>(defaultValues)

const DeveloperAuthProvider = ({ children }: PropsWithChildren) => {
  const { fetch } = useController()
  const [companyId, setCompanyId] = useState<Data.ID>()
  const { setValue, getValue, removeValue } = useLocalStorage()

  const { notifyOnError } = useNotification()
  const [authType, setAuthType] = useState<AdminAuthType>()
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const [adminUser, setAdminUser] = useState<AdminEntity | undefined>()

  const fetchAccount = useCallback(async () => {
    let userInfo = getValue<DeveloperProps>('pv.developer')
    if (!userInfo) return
    return await fetch(AdminResource.get, { id: userInfo.userId })
  }, [fetch])

  const init = useCallback(async () => {
    await fetchToken('developer').then((token) => {
      if (token !== null) {
        fetchAccount().then((response) => {
          setAdminUser(response)
          setAuthenticated(true)
          const props = getValue<DeveloperProps>('pv.developer')
          if (props) {
            setAuthType(props.tokenType)
            setCompanyId(props.companyId)
          }
        })
      } else {
        setAuthenticated(false)
      }
    })
  }, [fetch])

  const handleAuthenticate = (token: AdminTokenEntity) => {
    setAuthType(token.tokenType as AdminAuthType)
    setValue('pv.developer', {
      userId: token.userId,
      companyId: token.companyId,
      tokenType: token.tokenType,
    })
    if (token.companyId) setCompanyId(token.companyId)
    setAdminUser(token.user)
    setToken(token, 'developer')
    setAuthenticated(true)
  }

  const authenticate = (username: string, password: string) => {
    return fetch(AdminResource.login, {
      username,
      password,
      grantType: 'password',
    })
      .then(handleAuthenticate)
      .catch(notifyOnError)
  }

  const switchCompany = async (id: Data.ID) => {
    return new Promise<void>(async (resolve, reject) => {
      const token = await fetchFull('developer')
      if (token === null || token.token === null || token.refresh === null) return reject()
      const response = await fetch(AdminResource.switchCompany, {
        grantType: 'access_token',
        companyId: id,
        accessToken: token.token,
        refreshToken: token.refresh,
      })
      handleAuthenticate(response)
      resolve()
    })
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <DeveloperAuth.Provider
      value={{
        authenticated,
        adminUser,
        companyId,
        authenticate,
        switchCompany,
        onLogout: () => setAdminUser(undefined),
        authType,
      }}
    >
      {children}
    </DeveloperAuth.Provider>
  )
}

const useDeveloperAuth: () => ContextProps = () => useContext(DeveloperAuth)

export { DeveloperAuthProvider, useDeveloperAuth }
