import { FC, useMemo } from 'react'

import { Col, Radio, RadioGroupProps as RadioProps, Row } from 'antd'
import { RadioButtonProps } from 'antd/es/radio/radioButton'

import RadioCard, { RadioCardProps } from './RadioCard'
import styles from './styles/radiogroup.module.scss'

type RadioGroupButtonStyle = 'outline' | 'solid' | 'card'

type BaseRadioGroupProps = {
  gridSize?: number
} & Omit<RadioProps, 'buttonStyle'>

type RadioGroupProps =
  | ({
      buttonStyle?: Exclude<RadioGroupButtonStyle, 'card'>
      items: RadioButtonProps[]
    } & BaseRadioGroupProps)
  | ({
      buttonStyle: 'card'
      items: (RadioButtonProps & RadioCardProps)[]
    } & BaseRadioGroupProps)

const RadioGroup: FC<RadioGroupProps> = ({ gridSize = 1, items, buttonStyle, ...props }) => {
  const radioItems = useMemo(() => {
    return items.map((item: RadioButtonProps & RadioCardProps, index: number) => {
      if (buttonStyle === 'card') {
        return (
          <Col span={24} lg={24 / gridSize} key={index}>
            <RadioCard
              name={props.name}
              key={index}
              description={item.description}
              value={item.value}
              disabled={item.disabled || props.disabled}
              {...item}
            />
          </Col>
        )
      }
      return <Radio.Button className={styles.radioButton} key={index} {...item} />
    })
  }, [items, buttonStyle])

  return (
    <Radio.Group className={styles.radioGroup} buttonStyle={'solid'} {...props}>
      <Row gutter={[16, 16]}>{radioItems}</Row>
    </Radio.Group>
  )
}

export default RadioGroup
