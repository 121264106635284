import { Entity } from '@data-client/rest'

class Passbook extends Entity {
  readonly appleWallet: string = ''
  readonly googleWallet: string = ''
  readonly wallet: string = ''

  pk(parent?: any, key?: string | undefined): string | undefined {
    return undefined
  }
}

export { Passbook }
