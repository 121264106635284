import {
  type Dispatch,
  FC,
  PropsWithChildren,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { type IntlConfig, IntlProvider, useIntl } from 'react-intl'

import { useController } from '@data-client/react'

import { messages } from '../configs/portal/intl.json'
import { dayjs } from '../services/dayjs'
import { useConfig } from './ConfigProvider'

type LanguageId = Lowercase<string>
type CountryId = Uppercase<string>
type LocaleId = `${Lowercase<LanguageId>}-${Uppercase<CountryId>}` | string

const DEFAULT_LOCALE: Readonly<LocaleId> = 'en-US' as const
const [DEFAULT_LANGUAGE, DEFAULT_COUNTRY] = DEFAULT_LOCALE.split('-') as [LanguageId, CountryId]
const DEFAULT_CURRENCY = 'USD' as const
const DEFAULT_TIMEZONE = 'UTC' as const

const Context = createContext<{
  locale: {
    language: LanguageId
    country: CountryId
    currency: string
    id: LocaleId
  }
  setLocale: Dispatch<SetStateAction<LocaleId>>
}>({
  locale: { language: DEFAULT_LANGUAGE, country: DEFAULT_COUNTRY, currency: DEFAULT_CURRENCY, id: DEFAULT_LOCALE },
  setLocale: () => undefined,
})

const I18nProvider: FC<PropsWithChildren> = ({ children }) => {
  const { intl } = useConfig()
  const currency = intl.currency ?? DEFAULT_CURRENCY
  const timezone = intl.timezone
  const country = intl.country ?? DEFAULT_COUNTRY
  const language = intl.language ?? DEFAULT_LANGUAGE
  const [locale, setLocale] = useState(
    localStorage.getItem('locale') ?? [language.toLowerCase(), country.toUpperCase()].join('-'),
  )

  const localeMessages = useMemo(() => {
    const localeLanguage = locale.split('-')[0]
    return intl.messages[localeLanguage] ?? messages[localeLanguage] ?? messages[DEFAULT_LANGUAGE]
  }, [locale, intl.messages])

  const formats: IntlConfig['formats'] = {
    number: {
      money: {
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency,
      },
      credits: {
        style: 'decimal',
      },
    },
  }

  useEffect(() => {
    dayjs.locale(language)
  }, [language])

  const { invalidateAll } = useController()
  return (
    locale && (
      <Context.Provider
        value={{
          locale: {
            language: locale.split('-')[0].toLowerCase() as LanguageId,
            country: locale.split('-')[1].toUpperCase() as CountryId,
            id: locale,
            currency,
          },
          setLocale: async (locale: string) => {
            setLocale(locale)
            localStorage.setItem('locale', locale)
            await invalidateAll({ testKey: () => true })
          },
        }}
      >
        <IntlProvider
          formats={formats}
          messages={localeMessages}
          defaultLocale={DEFAULT_LOCALE}
          locale={locale}
          onError={(err) => {}}
          timeZone={timezone}
          defaultRichTextElements={{
            bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
          }}
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  )
}

const useI18n = (options?: { currency?: string }) => {
  const { intl } = useConfig()
  const { formatMessage, formatDate, formatTime, formatNumber } = useIntl()

  const t = useCallback(
    (text: string, ctx = {}) => (text ? formatMessage({ id: text, defaultMessage: text }, ctx) : text),
    [formatMessage],
  )
  const money = useCallback(
    (value: number, currency?: string) =>
      formatNumber(value, { format: 'money', currency: currency || options?.currency || intl.currency }),
    [formatNumber, intl.currency, options],
  )
  const date = useCallback(
    (value: Date | [Date, Date], options?: Parameters<typeof formatDate | typeof formatTime>[1]) =>
      Array.isArray(value) ? value.map((it) => formatDate(it, options)).join(' - ') : formatDate(value, options),
    [formatDate],
  )
  return {
    ...useContext(Context),
    t,
    money,
    date,
  }
}

export { I18nProvider, useI18n }
