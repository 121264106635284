import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { useApp } from '@peoplevine/sdk/components/app'
import { Spinner } from '@peoplevine/sdk/components/loader'
import { AccountEntity, AccountFlag } from '@peoplevine/sdk/datasource/account/account'
import { GlobalNetworkCompany } from '@peoplevine/sdk/datasource/globalNetwork'
import { useAuth } from '@peoplevine/sdk/providers'
import routes from '@peoplevine/sdk/routes'

import { CreatePassword } from 'src/components/user/password/create/CreatePassword'

type AccountContext = {
  customer: AccountEntity
  flag: AccountFlag
  isActiveMember: boolean
  failedPayment: boolean
  houseAccountEnabled: boolean
  globalNetworks?: GlobalNetworkCompany[]
}

const defaultValue: AccountContext = {
  customer: new AccountEntity(),
  flag: '',
  failedPayment: false,
  isActiveMember: false,
  houseAccountEnabled: false,
  globalNetworks: [],
}

const Account = createContext<AccountContext>(defaultValue)

function AccountProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate()
  const { confirm } = useApp().modal
  const { user } = useAuth()

  const customer = useMemo(() => AccountEntity.fromJS(user ?? {}), [user])
  useEffect(() => {
    if (user?.flag) {
      switch (user.flag) {
        case 'changepw':
        case 'createpw':
          const modal = confirm({
            centered: true,
            closable: false,
            maskClosable: false,
            footer: null,
            mask: true,
            width: 600,
            content: (
              <CreatePassword
                action={user.flag === 'createpw' ? 'create' : 'change'}
                onSuccess={() => modal.destroy()}
              />
            ),
          })
          break
        case 'membership_failed_payment':
        case 'membership_cancelled':
        case 'membership_expired':
        case 'membership_suspended':
        case 'membership_pending':
          navigate(routes.membershipPaywall)
      }
    }
  }, [user?.flag, confirm, navigate])

  if (!user) return <Spinner fullscreen />

  return (
    <Account.Provider
      value={{
        customer,
        flag: user.flag ?? '',
        failedPayment: customer.flag === 'membership_failed_payment',
        isActiveMember: customer.isMember,
        houseAccountEnabled: customer.houseAccountEnabled ?? false,
        globalNetworks: customer.globalNetworks,
      }}
    >
      {children}
    </Account.Provider>
  )
}

const useAccount: () => AccountContext = () => useContext(Account)

export { AccountProvider, useAccount }
