import { type CSSProperties, FC, PropsWithChildren, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { Layout } from 'antd'

import { useColor } from '@peoplevine/sdk/components/app'
import { Spinner } from '@peoplevine/sdk/components/loader'

import PublicView from 'src/components/public'

const Unauthorized: FC<PropsWithChildren<{ style?: CSSProperties }>> = ({ style, children = <Outlet /> }) => {
  const { token } = useColor()
  return (
    <Layout style={{ backgroundColor: token.colorBgLayout, ...style }}>
      <PublicView>
        <Suspense fallback={<Spinner />}>{children}</Suspense>
      </PublicView>
    </Layout>
  )
}
export default Unauthorized
