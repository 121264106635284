import { Entity } from '@data-client/rest'
import { ApiEndpoint, createApiResource } from './api/endpoint'
import { ApiEntity } from './api/entity'

export type ShippingOption = {
  id: string
  method?: string
  courier?: API.ShippingCourier
  courierCode?: string
  price?: number
  type?: API.ShippingOptionType
}

class ShippingRatesEntity extends ApiEntity {
  static readonly urlRoot = `/api/checkout/shipping/rates`
  readonly rates: ShippingOptionEntity[] = []
  readonly expiration?: Date
}

class ShippingOptionEntity extends ApiEntity implements ShippingOption {
  static readonly urlRoot = `/api/checkout/shipping/rates`
  readonly id: string = ''
  readonly method?: string = ''
  readonly courier?: API.ShippingCourier
  readonly courierCode?: string = ''
  readonly price?: number = 0
  readonly type?: API.ShippingOptionType

  pk() {
    return this.id
  }
}

type LocalPickupHours = {
  sunday: string
  monday: string
  tuesday: string
  wednesday: string
  thursday: string
  friday: string
  saturday: string
}

type LocalPickupNextAvailable = {
  day: string
  opens: string
  closes: string
}

export type LocalPickup = {
  id?: string
  name: string
  hours?: LocalPickupHours
  address?: Data.Address
  isOpen: boolean
  pickupToday: boolean
  closeToday: boolean
  nextAvailable?: LocalPickupNextAvailable
} & Data.Identified

export type ShippingAddress = {
  firstName?: string
  lastName?: string
  address: string
  address2?: string
  city: string
  state: string
  zipCode: string
  country: string
  notes?: string
}

class ShippingAddressEntity extends Entity implements ShippingAddress {
  readonly firstName?: string = ''
  readonly lastName?: string
  readonly address: string = ''
  readonly address2?: string = ''
  readonly city: string = ''
  readonly state: string = ''
  readonly zipCode: string = ''
  readonly country: string = ''
  readonly type?: string
  readonly notes?: string
  readonly residential: boolean = true

  fullName(): string {
    return this.firstName || this.lastName ? `${this.firstName} ${this.lastName}` : ''
  }

  pk() {
    return `${this.address}${this.zipCode}`
  }
}

class LocalPickupEntity extends ApiEntity implements LocalPickup {
  static readonly urlRoot = `/api/checkout/shipping/local`
  readonly id: string = ''
  readonly name: string = ''
  readonly hours?: LocalPickupHours
  readonly address?: Data.Address
  readonly isOpen: boolean = false
  readonly pickupToday: boolean = false
  readonly closeToday: boolean = false
  readonly nextAvailable?: LocalPickupNextAvailable

  get addressString(): string {
    const address = this.address
    return `${address?.address} ${address?.address2 ?? `| ${address?.address2}`}, ${address?.city} ${address?.state}`
  }
}

const ShippingResourceBase = createApiResource({
  path: '/api/checkout/shipping/:entity',
  schema: ShippingRatesEntity,
})

const rates = new ApiEndpoint({
  path: '/api/checkout/shipping/rates',
  method: 'POST',
  body: {} as ShippingAddress,
  schema: ShippingRatesEntity,
})

const localOptions = new ApiEndpoint({
  path: '/api/checkout/shipping/local',
  method: 'GET',
  schema: [LocalPickupEntity],
})
const ShippingResource = {
  ...ShippingResourceBase,
  rates,
  localOptions,
}

export { ShippingAddressEntity, ShippingOptionEntity, ShippingRatesEntity, LocalPickupEntity, ShippingResource }
