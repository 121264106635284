import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  lazy,
  useCallback,
  useContext,
  useMemo,
  useState,
  useTransition,
} from 'react'

import { CenteredContent } from '@peoplevine/sdk/components'
import { FullScreenDrawer } from '@peoplevine/sdk/components/drawer'
import { DrawerCloseButton } from '@peoplevine/sdk/components/drawer/DrawerCloseButton'
import Result from '@peoplevine/sdk/components/result'

type DrawerTypes = 'order' | 'creditCard'

type DrawerFunc = {
  show: () => void
  loading: boolean
  Drawer: ReactNode
}

type CustomerDrawerFunc = {
  show: (id: Data.ID) => void
  loading: boolean
  Drawer: ReactNode
}

type DrawerFuncs = {
  [key in DrawerTypes as `${key}Drawer`]: (id: Data.ID) => DrawerFunc
} & {
  customDrawer: (component: FC<Data.Identified>) => CustomerDrawerFunc
}

const DrawerComponents: { [key in DrawerTypes]: FC<Data.Identified> } = {
  order: lazy(() => import('../components/account/tabs/order/Order')),
  creditCard: lazy(() => import('../components/account/tabs/wallet/credit-card/CreditCardEdit')),
}

const DefaultDrawerFunc = {
  show: () => {},
  loading: false,
  Drawer: <Result.NotFound />,
}
type ContextProps = {} & DrawerFuncs

const defaultValues: ContextProps = {
  customDrawer: () => DefaultDrawerFunc,
  orderDrawer: () => DefaultDrawerFunc,
  creditCardDrawer: () => DefaultDrawerFunc,
}

const DrawerDetails = createContext<ContextProps>(defaultValues)

const DrawerLayout: FC<
  PropsWithChildren<{
    id: Data.ID
    open: boolean
    onClose: (id: Data.ID) => void
    afterOpenChange: (open: boolean) => void
  }>
> = ({ id, open = false, onClose, afterOpenChange, children }) => {
  return (
    <FullScreenDrawer
      closeIcon={<DrawerCloseButton drawerPlacement={'fullscreen'} />}
      open={open}
      mask={false}
      forceRender={true}
      destroyOnClose={false}
      maskClosable={false}
      onClose={() => onClose(id)}
      afterOpenChange={afterOpenChange}
      styles={{
        header: {
          borderBottom: 'none',
          height: 0,
          paddingBottom: 0,
        },
        body: { padding: 16 },
      }}
    >
      <CenteredContent width={1200}>{children}</CenteredContent>
    </FullScreenDrawer>
  )
}

const DrawerDetailProvider: FC<PropsWithChildren> = ({ children }) => {
  const useDrawerComponent = (Component: FC<Data.Identified>, id: Data.ID | null) => {
    // active/open states separated to allow the drwaer to smoothly close before unmounting
    const [drawerState, setDrawerState] = useState({
      active: false,
      open: false,
    })

    const [loading, startTransition] = useTransition()
    const handleOpen = useCallback(() => {
      startTransition(() => setDrawerState({ active: true, open: true }))
    }, [setDrawerState])
    const Drawer = useMemo(
      () =>
        id && drawerState.active ? (
          <DrawerLayout
            id={id}
            open={drawerState.open}
            onClose={() => setDrawerState({ active: true, open: false })}
            afterOpenChange={(open) => {
              if (!open) {
                setDrawerState({ active: false, open: false })
              }
            }}
          >
            <Component id={id} />
          </DrawerLayout>
        ) : null,
      [drawerState, id],
    )

    return {
      show: handleOpen,
      loading,
      Drawer,
    }
  }

  const useCustomDrawer = (Component: FC<Data.Identified>) => {
    // active/open states separated to allow the drwaer to smoothly close before unmounting
    const [drawerState, setDrawerState] = useState<{
      id: Data.ID | null
      active: boolean
      open: boolean
    }>({
      id: null,
      active: false,
      open: false,
    })

    const [loading, startTransition] = useTransition()
    const handleOpen = useCallback(
      (id: Data.ID) => {
        startTransition(() => setDrawerState({ active: true, open: true, id: id }))
      },
      [setDrawerState],
    )

    const Drawer = useMemo(
      () =>
        drawerState.id && drawerState.active ? (
          <DrawerLayout
            id={drawerState.id}
            open={drawerState.open}
            onClose={() => setDrawerState((prev) => ({ ...prev, active: true, open: false }))}
            afterOpenChange={(open) => {
              if (!open) {
                setDrawerState((prev) => ({ ...prev, active: false, open: false }))
              }
            }}
          >
            <Component id={drawerState.id} />
          </DrawerLayout>
        ) : null,
      [drawerState],
    )

    return {
      show: handleOpen,
      loading,
      Drawer,
    }
  }
  const useDrawer = (type: DrawerTypes) => (id: Data.ID) => useDrawerComponent(DrawerComponents[type], id)

  return (
    <DrawerDetails.Provider
      value={{
        customDrawer: useCustomDrawer,
        orderDrawer: useDrawer('order'),
        creditCardDrawer: useDrawer('creditCard'),
      }}
    >
      {children}
    </DrawerDetails.Provider>
  )
}

const useDrawerDetails: () => ContextProps = () => useContext(DrawerDetails)

export { DrawerDetailProvider, useDrawerDetails }
