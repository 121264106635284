import { CSSProperties, FC, PropsWithChildren } from 'react'

import { ConfigProvider, Typography } from 'antd'

import { useColor } from '../app'
import { Skeleton } from '../skeleton/Skeleton'
import { withTranslation } from './Text'

type TitleProps = SDK.Components.TextProps & {
  level?: 1 | 2 | 3 | 4 | 5
  fontSize?: string | number
  weight?: number
  fontFamily?: 'primary' | 'secondary'
}

const Title: FC<PropsWithChildren<TitleProps>> = withTranslation(
  ({
    loading = false,
    uppercased = false,
    type = 'default',
    level = 5,
    size,
    weight: defaultWeight,
    fontSize: defaultSize,
    translate = 'yes',
    font = {
      family: 'secondary',
      weight: defaultWeight,
      size: defaultSize,
    },
    ellipsis = false,
    children,
    ...props
  }) => {
    const { token, textByVariant } = useColor()

    const isPrimary = font?.family === 'primary' || level === 1

    const color = type ? (type === 'default' ? token.headingColor : textByVariant(type)) : props.color
    const fontFamily = isPrimary ? token?.headingFontFamily : token?.headingFontFamilySecondary
    const fontSize = token[`fontSizeHeading${level}`]
    const fontWeight = font.weight
      ? font.weight
      : isPrimary
        ? (token.headingFontWeight ?? 900)
        : token.headingSecondaryFontWeight
    const lineHeight = token[`lineHeightHeading${level}`]
    const textTransform = isPrimary || uppercased ? 'uppercase' : ('capitalize' as CSSProperties['textTransform'])

    const style = { textTransform, fontFamily, fontSize, fontWeight, lineHeight, color }

    if (loading) return <Skeleton height={style.fontSize} width={200} />

    return (
      <ConfigProvider
        componentSize={size}
        theme={{
          components: {
            Typography: {
              fontFamily: style.fontFamily,
              colorText: style.color,
              algorithm: true,
            },
          },
        }}
        typography={{
          style: {
            textOverflow: 'ellipsis',
            margin: 0,
            marginBlockEnd: 0,
            ...style,
          },
        }}
      >
        <Typography.Title ellipsis={ellipsis} data-cy={'Title'} data-title={children} level={level} {...props}>
          {children}
        </Typography.Title>
      </ConfigProvider>
    )
  },
)

export { Title }
