

// class PaginatedResponse<E extends Entity> extends schema.Object {
//   constructor(schema: E) {
//     super({
//       results: new ApiCollection<E[]>([schema]),
//       pagination: PaginatedHeader,
//     });
//   }
// }


class PaginatedHeader implements API.Pagination {
  pageNumber: number = 0
  pageSize: number = 0
  totalPages: number = 0
  totalCount: number = 0
  hasPreviousPage: boolean = false
  hasNextPage: boolean = false
}


export { PaginatedHeader }

