import { PropsWithChildren, useEffect } from 'react'

import { Form as AbstractForm, FormInstance, FormProps, FormRule } from 'antd'

import { useLoading } from '@data-client/hooks'

import { Item } from '../form/index'
import { OverlayLoader } from '../loader'

type FormRuleProps<T> = {
  [key in keyof T]: FormRule[]
}

const Form = <T,>({
  onValidate = () => {},
  initialValues,
  skipInitialValidation = true,
  onChange,
  form,
  ...props
}: PropsWithChildren<
  FormProps<T> & { onValidate?: (valid: boolean, values: T) => void; skipInitialValidation?: boolean }
>) => {
  const [validate, loading] = useLoading(
    async (form: FormInstance) =>
      await form
        .validateFields()
        .then((values) => ({ valid: true, values }))
        .catch((values) => ({ valid: false, values })),
    [],
  )

  useEffect(() => {
    if (!skipInitialValidation) {
      initialValues && form && validate(form).then(({ valid, values }) => onValidate(valid, values))
    }
  }, [form, initialValues, onValidate, skipInitialValidation, validate])

  return (
    <OverlayLoader loading={loading}>
      <AbstractForm<T> initialValues={initialValues} form={form} layout={'vertical'} size={'middle'} {...props}>
        {props.children}
      </AbstractForm>
    </OverlayLoader>
  )
}

Form.Item = (props) => <Item {...props} />

Form.useWatch = AbstractForm.useWatch
Form.useForm = AbstractForm.useForm
Form.useFormInstance = AbstractForm.useFormInstance

export { Form, type FormProps, type FormRuleProps }
