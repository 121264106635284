import { ApiEntity, Singleton } from './api/entity'

class AbstractTokenEntity extends Singleton {
  readonly accessToken: string = ''
  readonly companyId: number = 0
  readonly expiresAt: string
  readonly expiresIn: number = 0
  readonly refreshExpiresAt: string = ''
  readonly refreshExpiresIn: number = 0
  readonly refreshToken: string = ''
  readonly sessionId: string = ''
  readonly ssoToken: string = ''
  readonly tokenType: 'user' | 'user_company' | 'customer'
  readonly wsToken: string = ''

  pk() {
    return this.accessToken
  }
}

class PasswordReset extends ApiEntity {
  static readonly urlRoot = `/api/public/verify/password-reset`
  readonly password: string = ''
  code: string = ''
  username: string = ''
  email: string = ''
  callback: string = ''

  pk(): string {
    return this.password
  }
}

export type PasswordCodeVerifyResponse = {
  readonly success: boolean
  readonly message: string
}

export type PasswordResetResponse = {
  readonly success: boolean
  readonly message: string
}

export { AbstractTokenEntity, PasswordReset }
