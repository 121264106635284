import { FC, ReactNode, useEffect, useRef, useState } from 'react'

import { withPrefix } from '../../providers/ConfigProvider'

type StepsSliderProps = {
  active?: number
  onChange?: (active: number) => void
  elements: ReactNode[]
  destroyOnHide?: boolean
}
const StepsSlider: FC<StepsSliderProps> = ({ active = 0, onChange, elements, destroyOnHide }) => {
  const [current, setCurrent] = useState(0)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const getStatusClass = (index: number) => {
    if (index === current) return withPrefix('steps-slider-item-active')
    if (index === current - 1) return withPrefix('steps-slider-item-prev')
    if (index === current + 1) return withPrefix('steps-slider-item-next')
    return ''
  }

  useEffect(() => {
    setCurrent(active)
    onChange && onChange(active)
  }, [active, onChange])

  return (
    <div ref={wrapperRef} className={withPrefix('steps-slider')}>
      {elements.map((element, index) => (
        <div
          key={`step-item-${index}`}
          className={withPrefix('steps-slider-item', getStatusClass(index))}
          style={{
            zIndex: index,
            display: current === index ? 'block' : 'none',
          }}
        >
          {element}
        </div>
      ))}
    </div>
  )
}

interface StepsSliderV2Item {
  key: string
  enabled?: boolean
  element: ReactNode
}

type StepsSliderV2Props = {
  active: string
  onChange?: (active?: string) => void
  items: StepsSliderV2Item[]
}
const StepsSliderV2: FC<StepsSliderV2Props> = ({ active, onChange, items }) => {
  const activeIndex = items.findIndex((item) => item.key === active)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const getStatusClass = (index: number) => {
    if (index === activeIndex) return withPrefix('steps-slider-item-active')
    if (index === activeIndex - 1) return withPrefix('steps-slider-item-prev')
    if (index === activeIndex + 1) return withPrefix('steps-slider-item-next')
    return ''
  }

  return (
    <div ref={wrapperRef} className={withPrefix('steps-slider')}>
      {items
        .filter((item) => item.enabled !== false)
        .map((item, index) => (
          <div
            key={`step-item-${index}`}
            className={withPrefix('steps-slider-item', getStatusClass(index))}
            style={{ zIndex: index, transform: activeIndex === index ? `translate3d(${index * -100}%, 0, 0)` : '' }}
          >
            {item.element}
          </div>
        ))}
    </div>
  )
}

export { StepsSlider, StepsSliderV2 }
