import { FC } from 'react'

import { Flex } from 'antd'

import { HelpText, Item, Label, RadioGroup } from '.'
import Tag from '../tag'
import { RadioCardProps } from './radio/RadioCard'

const billingUse: { [key in API.WalletBillingUse]: RadioCardProps } = {
  default: {
    title: 'Default Method',
    icon: 'application/audit',
    description: 'This payment method will be used for any transactions without a specified payment method.',
  },
  billing: {
    title: 'Membership Dues',
    icon: 'application/credit-card',
    description: 'This payment method will be used for membership dues. Please select the memberships below.',
  },
  pos: {
    title: 'Food & Beverage',
    icon: 'custom/drinks',
    description: 'This payment method will be used for food and beverage transactions.',
  },
}

interface IBillingUse {
  gridSize?: number
  initialValue?: API.WalletBillingUse
}

const BillingUseTag: FC<{ billingUse: API.WalletBillingUse }> = ({ billingUse = '' }) => {
  switch (billingUse) {
    case 'default':
      return (
        <Tag size={'small'} type={'success'}>
          Default
        </Tag>
      )
    case 'billing':
      return (
        <Tag size={'small'} type={'processing'}>
          Membership Dues
        </Tag>
      )
    case 'pos':
      return (
        <Tag size={'small'} type={'warning'}>
          Food & Beverage
        </Tag>
      )

    default:
      return null
  }
}

const BillingUse: FC<IBillingUse> = ({ gridSize, initialValue }) => {
  return (
    <Item
      size={14}
      name={'billingUse'}
      label={
        initialValue === 'default' ? (
          <Flex vertical style={{ width: '100%', marginBottom: 8 }}>
            <Label strong size={14}>
              Use this payment method for
            </Label>

            <HelpText
              helperText={
                'At least one payment method must be set as default. To update the billing use, a different payment method must be set as default.'
              }
            />
          </Flex>
        ) : (
          'Use this payment method for'
        )
      }
      strong
    >
      <RadioGroup
        gridSize={gridSize}
        buttonStyle={'card'}
        name={'billingUse'}
        items={Object.entries(billingUse).map(([value, { title, description, icon }]) => ({
          value,
          title,
          description,
          icon,
          disabled: initialValue === 'default',
        }))}
      />
    </Item>
  )
}

export { BillingUseTag, BillingUse as default }
