import * as React from "react";
const SvgSuntrustBank = (props) => /* @__PURE__ */ React.createElement("svg", { xmlSpace: "preserve", xmlnsXlink: "http://www.w3.org/1999/xlink", style: {
  enableBackground: "new 0 0 484.7 342.8"
}, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 484.7 342.8", width: "1em", height: "1em", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "a", d: "m5.6 216.8-2.8 7.8c-2.4 6.6-2.8 18.3-1 24.5l14.5 75c1.1 6.5 3.2 9.2 5.4 8.8 2.4-.6 2.1-5.6.7-16.8l-11-99.4c-.7-6.2-3.4-6.3-5.8.1" })), /* @__PURE__ */ React.createElement("clipPath", { id: "b" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#a", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#b)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "c", x1: 354.408, x2: 355.408, y1: 73.332, y2: 73.332, gradientTransform: "scale(-403.5581 403.5581)rotate(-42.159 83.555 499.73)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M461.9 543.7h19.2v-98.4h-19.2v98.4z", style: {
  fill: "url(#c)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "d", d: "M36.3 295.6c.3 6.5 1 14.3-1.3 14.8-2 .4-3.3-2.1-4.4-10.4L26 255.6c-.9-6.4.3-19.8 2.6-24.5 2.4-4.7 4.6-3.5 4.9 2.8l2.8 61.7z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "e" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#d", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#e)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "f", x1: 354.413, x2: 355.413, y1: 73.325, y2: 73.325, gradientTransform: "scale(-397.0407 397.0407)rotate(-42.159 83.558 499.784)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M482.1 530.8h9.7v-67.5h-9.7v67.5z", style: {
  fill: "url(#f)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "g", d: "m56 146.5-5.6 129c-.1 5.9-.5 13.3-2.7 12.8-1.2-.3-2.7-3.7-2.8-9.6l-2.5-105.9c-.2-5.3 1.9-14 4.7-19.3l3.9-7.3c2.8-5 5.5-4.9 5 .3" })), /* @__PURE__ */ React.createElement("clipPath", { id: "h" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#g", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#h)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "i", x1: 354.43, x2: 355.43, y1: 73.306, y2: 73.306, gradientTransform: "scale(-374.6082 374.6082)rotate(-42.159 83.735 499.917)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M495.8 599h11.4V480.8h-11.4V599z", style: {
  fill: "url(#i)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "j", d: "m75.4 172.9-8.8 98.4c-.9 6.9-1.9 14.1-4 14-1.4 0-2.6-4.4-1.9-10.9l3.6-84.5c.6-6.1 4.7-15.3 7.3-20 2.8-4.4 4.8-2.3 3.8 3" })), /* @__PURE__ */ React.createElement("clipPath", { id: "k" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#j", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#k)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "l", x1: 354.418, x2: 355.418, y1: 73.317, y2: 73.317, gradientTransform: "scale(-389.1196 389.1196)rotate(-42.159 83.614 499.833)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M510 579.6h13.1v-96H510v96z", style: {
  fill: "url(#l)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "m", d: "M78.8 264.4c-1.7-.2-1.8-7.2-1.3-11.9l13.8-136.2c.8-5 4.4-12.8 8.1-17.3l1.3-1.6c3.8-4.5 6.5-4.1 5.5.8L82.9 252.6c-1.2 6.6-2.1 11.8-4.1 11.8" })), /* @__PURE__ */ React.createElement("clipPath", { id: "n" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#m", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#n)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "o", x1: 354.422, x2: 355.422, y1: 73.312, y2: 73.312, gradientTransform: "scale(-385.012 385.012)rotate(-42.159 83.688 499.85)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M523.5 637.7h24.2V500.5h-24.2v137.2z", style: {
  fill: "url(#o)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "p", d: "M119.9 120.6c3-3.4 8.1-1.5 7 3.4L96.7 261.6c-1.1 4.9-3.2 12.5-5.5 12.3-1.8-.2-1.7-7.4-.4-13.2l21.7-124.4c1.1-5 4.3-12.3 7.4-15.7" })), /* @__PURE__ */ React.createElement("clipPath", { id: "q" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#p", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#q)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "r", x1: 354.412, x2: 355.412, y1: 73.321, y2: 73.321, gradientTransform: "scale(-398.5175 398.5175)rotate(-42.159 83.566 499.777)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M533.4 618.2h30.9V492.6h-30.9v125.6z", style: {
  fill: "url(#r)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "s", d: "M123 260.1c-2.2 4.7-4.9 11.6-8 10.3-2.2-.9 2.2-11.6 2.2-11.6L192 76.3c2.4-5.3 7.8-12.1 13.6-9.3 5.2 2.5-.4 12.8-2.4 17.4-45.4 102-58.3 128.3-80.2 175.7" })), /* @__PURE__ */ React.createElement("clipPath", { id: "t" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#s", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#t)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "u", x1: 354.502, x2: 355.502, y1: 73.215, y2: 73.215, gradientTransform: "scale(-301.2955 301.2955)rotate(-42.159 84.032 500.592)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M552.2 660.6h78.4v-166h-78.4v166z", style: {
  fill: "url(#u)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "v", d: "M139.8 251.1c1.8 1.4 6-3.6 9.3-9.3L285.9 13.7c2.4-4.1 3.8-10.7-1.1-13.2-3.8-1.9-10.5 1.8-13.6 7.4L144 237.6c-3.8 7-6 12.1-4.2 13.5" })), /* @__PURE__ */ React.createElement("clipPath", { id: "w" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#v", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#w)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "x", x1: 354.457, x2: 355.457, y1: 73.266, y2: 73.266, gradientTransform: "scale(-343.4323 343.4323)rotate(-42.159 83.766 500.18)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M572.4 713.1h121.4V510H572.4v203.1z", style: {
  fill: "url(#x)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "y", d: "M142.9 275.7c-1.8-1.8 1.3-7.4 4.9-13.1L294.1 50.4c4-6 12.7-12.7 18-9.5 4.8 2.8 4.9 8.8.8 14.5L153.3 266.6c-3.9 5.7-8.5 10.9-10.4 9.1" })), /* @__PURE__ */ React.createElement("clipPath", { id: "z" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#y", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#z)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "A", x1: 354.455, x2: 355.455, y1: 73.267, y2: 73.267, gradientTransform: "scale(-344.9505 344.9505)rotate(-42.159 83.66 500.217)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M574.9 681.8h140.7V489.9H574.9v191.9z", style: {
  fill: "url(#A)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "B", d: "M186.2 251.1s-9.7 10.1-11.7 7.9c-2.1-2.2 1.9-6.4 6.1-11.9l4.8-5.8L389.7 6.9c3.3-4 7.8-4.2 12.1-1l6.2 7.6c3.4 3.8 3.1 10.5-.6 14.2L186.2 251.1z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "C" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#B", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#C)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "D", x1: 354.424, x2: 355.424, y1: 73.299, y2: 73.299, gradientTransform: "scale(-382.316 382.316)rotate(-42.159 83.432 499.937)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M599.9 709.8h191.2V503H599.9v206.8z", style: {
  fill: "url(#D)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "E", d: "M169.6 288.6c-1.6-1.6 2-6.3 8.4-12L395 77.2c3.9-4 9.3-3.9 12.8-.5 4.6 4.3 6.1 8.4 6.8 12.2 1.2 6.3-2.3 9.1-6.7 13.1L182 282c-5.6 4.9-10.5 8.4-12.4 6.6" })), /* @__PURE__ */ React.createElement("clipPath", { id: "F" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#E", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#F)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "G", x1: 354.416, x2: 355.416, y1: 73.328, y2: 73.328, gradientTransform: "scale(-392.2155 392.2155)rotate(-42.159 83.271 499.92)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M596.4 653.4h198.3V479.7H596.4v173.7z", style: {
  fill: "url(#G)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "H", d: "M214.6 270.8 467 90.4c4.8-3.8 9.2-2.9 10.4 1.8l5.9 20.2c1.3 5-2.2 11.7-7.3 15.3L219 276.6c-6.3 4-12.9 7.6-14.5 5.4-2.1-2.7 4.3-6.5 10.1-11.2" })), /* @__PURE__ */ React.createElement("clipPath", { id: "I" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#H", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#I)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "J", x1: 354.409, x2: 355.409, y1: 73.348, y2: 73.348, gradientTransform: "scale(-401.8228 401.8228)rotate(-42.159 83.125 499.892)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M623.9 642.7h225.8V484.5H623.9v158.2z", style: {
  fill: "url(#J)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "K", d: "M187.7 308.7c-.7-3 5.9-5.7 13-9.3l225.9-113.9c6.1-3.4 11-1.7 10.9 3.6l1 25.1c-.1 5.5-5.9 12.2-12.9 15.1L203 306.8c-7.8 2.9-14.6 4.7-15.3 1.9" })), /* @__PURE__ */ React.createElement("clipPath", { id: "L" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#K", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#L)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "M", x1: 354.415, x2: 355.415, y1: 73.344, y2: 73.344, gradientTransform: "scale(-393.9553 393.9553)rotate(-42.159 83.08 499.973)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M611.5 566.3h201.2V462.8H611.5v103.5z", style: {
  fill: "url(#M)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "N", d: "m452.4 287.1-218.1 31.2c-8.5 1.3-14.8 2.7-15.5.2-.7-3 6.5-4.9 14.5-7.6l226.3-68.7c7.3-2.4 13 .2 12.3 5.9l-3.2 25.9c-.6 5.9-7.9 11.9-16.3 13.1" })), /* @__PURE__ */ React.createElement("clipPath", { id: "O" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#N", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#O)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "P", x1: 354.417, x2: 355.417, y1: 73.34, y2: 73.34, gradientTransform: "scale(-390.8363 390.8363)rotate(-42.159 82.97 500.048)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M636.4 520.1H840v-64.9H636.4v64.9z", style: {
  fill: "url(#P)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "Q", d: "M204.3 339.5c-.6-3.2 10.2-5 18.6-5.9L415.8 311c8.6-.9 15.7 3.7 12.9 11.2l-3.9 10.2c-2.3 6.5-9.4 11-18.4 10.4l-184.9.1c-8.1 0-16.6-.2-17.2-3.4" })), /* @__PURE__ */ React.createElement("clipPath", { id: "R" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#Q", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#R)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "S", x1: 354.414, x2: 355.414, y1: 73.331, y2: 73.331, gradientTransform: "scale(-394.8805 394.8805)rotate(-42.159 82.933 500.024)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M624.9 463.9h182.3v-26.6H624.9v26.6z", style: {
  fill: "url(#S)"
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "T", d: "M177.5 53.4s-55.9 171.7-59.1 180.9c-3.4 9.9-6.8 18.7-9.7 18-1.9-.6-.4-6.9 1.7-14.5l52.9-186.3c2.5-7.5 10.4-14 13.5-13.4 5.6 1.1 2.4 10.5.7 15.3" })), /* @__PURE__ */ React.createElement("clipPath", { id: "U" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#T", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#U)"
}, transform: "matrix(1.25 0 0 -1.25 -577.437 889.954)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "V", x1: 354.499, x2: 355.499, y1: 73.22, y2: 73.22, gradientTransform: "scale(-304.506 304.506)rotate(-42.159 84.1 500.507)", gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#f15d22"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#fdb913"
} })), /* @__PURE__ */ React.createElement("path", { d: "M547.5 682h60.4V509.6h-60.4V682z", style: {
  fill: "url(#V)"
} })));
export default SvgSuntrustBank;
