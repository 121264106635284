import { Form, FormInstance } from "antd"
import { useEffect, useState } from "react"
import { isEqualPartial } from "src/helpers/object"



type PropertyChange = {
  [key: string]: {
    current: any
    initial: any
    changed?: boolean
  }
}

const useChangeTracker = (form?: FormInstance) => {
  const [initialValues, setInitialValues] = useState()
  const [hasChanged, setHasChanged] = useState(false)
  const [changes, setChanges] = useState<PropertyChange[]>([])
  const values = Form.useWatch([], form)

  const getChanges = (initial: any, current: any) => {
    const changes: PropertyChange[] = []
    for (let key in initial) {
      const currentVal = current[key]
      const initialVal = initial[key]
      if (currentVal === null) continue
      if (initialVal !== currentVal) {
        if (typeof currentVal === 'object') {
          const objChanges = getChanges(initialVal, currentVal)
          if (objChanges.length > 0) {
            changes[key] = objChanges
          }
        } else {
          changes[key] = {
            current: currentVal,
            initial: initialVal,
            changed: initialVal !== currentVal
          }
        }
      }
    }
    return changes
  }

  useEffect(() => {
    setHasChanged(!isEqualPartial(initialValues, values))
    setChanges(getChanges(initialValues, values))
  }, [values])

  useEffect(() => {
    if (form) {
      setInitialValues(form.getFieldsValue())
    }
  }, [form])

  return [hasChanged, changes] as const
}

export default useChangeTracker