import { FC, useState } from 'react'

import { Col, Flex, Row } from 'antd'

import { useLoading } from '@data-client/hooks'

import Form, { Button, Item } from '@peoplevine/sdk/components/form'
import { FormItem } from '@peoplevine/sdk/components/form/FormItem'
import { useResponsive } from '@peoplevine/sdk/components/screen'
import Text from '@peoplevine/sdk/components/text/Text'
import { Title } from '@peoplevine/sdk/components/text/Title'

import { useActivate } from './ActivateProvider'

interface IActivateAccount {
  code: string
  customerId: number
}

const ActivateAccount: FC<IActivateAccount> = ({ code, customerId }) => {
  const { activate } = useActivate()
  const [enabled, setEnabled] = useState(false)
  const { isMobile } = useResponsive()
  const [handleActivate, activating] = useLoading(
    (password: string) =>
      activate({
        password,
        code: code,
        customerId: customerId,
      }),
    [code, customerId],
  )

  return (
    <Flex
      style={{ width: '100%', height: isMobile ? undefined : 'calc(100vh - 32px)', justifyItems: 'center' }}
      align={'center'}
      justify={'center'}
    >
      <Row gutter={[0, 16]} wrap justify={'center'} align='middle'>
        <Col span={24}>
          <Title level={1}>Set Your Password</Title>
          <Text type={'secondary'}>Let's secure your account with a new password.</Text>
        </Col>
        <Col span={24}>
          <Form<{ password: string }> onFinish={(values) => handleActivate(values.password)} disabled={activating}>
            <Flex vertical gap={16}>
              <FormItem.Password onValidate={setEnabled} name={'password'} showLevel />
              <Item>
                <Button
                  icon={'directional/arrow-right'}
                  loading={activating}
                  disabled={!enabled}
                  block
                  htmlType={'submit'}
                >
                  Continue
                </Button>
              </Item>
            </Flex>
          </Form>
        </Col>
      </Row>
    </Flex>
  )
}

export default ActivateAccount
