import { FC, PropsWithChildren } from 'react'

import { Flex } from 'antd'

export type CenteredContentProps = {
  width?: string | number
  height?: string | number
  verticalAlign?: boolean
} & PropsWithChildren

export const CenteredContent: FC<CenteredContentProps> = ({
  width = 1200,
  height,
  verticalAlign = false,
  children,
}) => {
  return (
    <Flex align={verticalAlign ? 'center' : 'start'} justify={'center'} style={{ width: '100%', minHeight: height }}>
      <div style={{ width: '100%', maxWidth: width, height: height }}>{children}</div>
    </Flex>
  )
}
