import { FC } from 'react'

import { useI18n } from 'src/providers'

import Text from './Text'

type MoneyProps = {
  icon?: Design.IvyIcon
  prefix: string
  suffix: string
  strike?: boolean
  wrap?: boolean
  strong?: boolean
  children: number
  currency?: string
} & Omit<SDK.Components.TextProps, 'children'>

const Money: FC<Partial<MoneyProps>> = ({
  icon,
  type,
  wrap = true,
  strong,
  prefix = '',
  suffix = '',
  strike = false,
  children = 0,
  currency,
  ...props
}) => {
  const { money } = useI18n()

  return (
    <Text
      {...props}
      type={type}
      strike={strike}
      strong={strong}
      context={{ amount: money(children, currency) }}
      icon={icon}
    >
      {[prefix, '{amount}', suffix].filter(Boolean).join(' ')}
    </Text>
  )
}

export { EmailLink } from './EmailLink'
export { Line } from './Line'
export { Link } from './Link'
export { PhoneLink } from './PhoneLink'
export { SmallText } from './SmallText'
export { default as Text } from './Text'
export { Title } from './Title'
export { Markup } from './markup/Markup'
export { Money }
