import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react'

import { Flex, Input, InputProps, InputRef } from 'antd'

type OneTimeCodeProps = {
  length: number
  value?: string
  disabled?: boolean
  onChange?: (otp: string) => void
  inputProps?: InputProps
  wrapperStyle?: React.CSSProperties
}

const OneTimeCode: FC<OneTimeCodeProps> = ({ length, value, onChange, disabled, inputProps, wrapperStyle }) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''))
  const inputRefs = useRef<(InputRef | null)[]>([])

  useEffect(() => {
    if (value) {
      const valueArray = value.split('').slice(0, length)
      setOtp([...valueArray, ...Array(length - valueArray.length).fill('')])
    }
  }, [value, length])

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const val = e.target.value
    if (/^[0-9]$/.test(val) || val === '') {
      const newOtp = [...otp]
      newOtp[index] = val
      setOtp(newOtp)
      onChange && onChange(newOtp.join(''))

      if (val !== '' && index < length - 1) {
        inputRefs.current[index + 1]?.focus()
      }
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  useEffect(() => {
    // Focus first input on mount
    const timer = setTimeout(() => {
      inputRefs.current[0]?.focus()
    }, 100)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Flex gap={8} align={'center'} style={wrapperStyle}>
      {otp.map((digit, index) => (
        <Input
          size={'large'}
          key={index}
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength={1}
          ref={(el) => (inputRefs.current[index] = el)}
          style={{ maxWidth: 40, textAlign: 'center' }}
          disabled={disabled}
          {...inputProps}
        />
      ))}
    </Flex>
  )
}

export default OneTimeCode
