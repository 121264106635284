import dayjs from 'dayjs'

import { capitalize } from '../../helpers/strings'
import { BillingAddress } from '../address'
import { ApiEntity } from '../api/entity'

abstract class Wallet extends ApiEntity implements API.WalletInterface {
  readonly nickname?: string = ''
  billingUse?: API.WalletBillingUse
  default: boolean = false
  readonly status: API.WalletStatus = 'active'
  readonly customerId?: number
  readonly lastFour: string = '0000'
  readonly expMonth: number
  readonly expYear: number
  readonly billingDetails?: BillingAddress = BillingAddress.fromJS({})
  readonly verified: boolean = false
  readonly brand: Design.PaymentBrandIcon
  readonly modifiedOn: Date = new Date()
  readonly createdOn: Date = new Date()
  readonly paymentProcessorId?: Data.ID
  readonly paymentType: API.WalletPaymentType
  get cardExpiry(): string {
    if (this.expMonth && this.expYear) {
      return `${Number.parseInt(`${this.expMonth}`) > 9 ? this.expMonth : `0${this.expMonth}`} / ${this.expYear
        .toString()
        .slice(-2)}`
    } else {
      return ''
    }
  }

  get expirationDate(): dayjs.Dayjs {
    return dayjs()
      .set('year', Number.parseInt(`20${this.expYear}`))
      .set('month', this.expMonth - 1)
  }

  get description() {
    return [capitalize(this.brand), this.lastFour].filter(Boolean).join(' - ')
  }
}

class HouseAccountWallet extends Wallet implements API.PaymentType<'houseAccount'> {
  readonly paymentType = 'houseAccount'
  get description() {
    return 'Charge to House Account'
  }
}

class CreditCardWallet extends Wallet implements API.PaymentType<'creditcard'> {
  readonly paymentType = 'creditcard'
  readonly type?: API.PaymentProcessor
}

class BankAccountWallet extends Wallet implements API.PaymentType<'ach'> {
  readonly paymentType = 'ach'
  readonly canVerify: boolean = true
  readonly type?: API.PaymentProcessor

  get description() {
    return this.brand.split(',')[0].replaceAll(' ', '-')
  }

  get bankName() {
    return this.brand.split(',')[0].replaceAll(' ', '-')
  }

  get title() {
    return this.brand
  }

  get verificationStatus() {
    return this.verified ? 'verified' : 'pending verification'
  }
}

export { BankAccountWallet, CreditCardWallet, HouseAccountWallet, Wallet }
